.Footer_Down_Travel{
  width: 100%;
  min-height: 30px;
  height: auto;
  background: #D9D4D9;
  letter-spacing: 0px;
  color: #000000;

}

.Footer_Down_Travel_center_left{
  padding-top: 22px;
  text-align: left;
  font: normal normal bold 13px/19px Alegreya;
  letter-spacing: 0.39px;
  color: #000000;
  margin-bottom: 20px;
}

.Footer_Down_Travel_center_right{
  padding-top: 22px;
  text-align: right;
}

.Footer_Down_Travel_center_right a{
  font: normal normal bold 13px/19px Alegreya;
  letter-spacing: 0.39px;
  color: #3D3D3D;
  padding-right: 15px;
}

@media screen and (max-width: 767px) {
  .Footer_Down_Travel_center_left{

    text-align: center;
  }
  .Footer_Down_Travel_center_right{
    padding-top: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
}