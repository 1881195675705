.word-list {
    display: flex;
    flex-wrap: wrap;
    /* Adăugat pentru a gestiona cuvintele pe mai multe linii la ecrane mici */
    justify-content: center;
    /* Așază cuvintele în centru pe ecrane mai mici */
}

.word-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    /* Spațiere între cuvinte pe ecrane mai mici */
}

.wordYello {
    margin-bottom: 10px;
    font: normal normal bold 30px/43px Amatic SC;
    color: #FFD504;
}

.wordBlack {
    margin-bottom: 10px;
    font: normal normal bold 30px/43px Amatic SC;
    color: #000000;
}

.underlineyellow {
    height: 2px;
    width: 203px;
    background-color: #FFD504;
}

.underlineblack {
    height: 2px;
    width: 203px;
    background-color: #000;
}

@media screen and (max-width: 1013px) {

    .wordYello,
    .wordBlack {
        font-size: 20px;
        /* Dimensiunea originală pentru ecrane medii și mari */
    }

    .underlineyellow,
    .underlineblack {
        width: 203px;
        /* Lungimea originală pentru ecrane medii și mari */
    }
}

@media screen and (min-width: 1013px) {

    .wordYello,
    .wordBlack {
        font-size: 30px;
        /* Dimensiunea originală pentru ecrane medii și mari */
    }

    .underlineyellow,
    .underlineblack {
        width: 233px;
        /* Lungimea originală pentru ecrane medii și mari */
    }
}

@media screen and (min-width: 1304px) {

    .wordYello,
    .wordBlack {
        font-size: 30px;
        /* Ajustează dimensiunea fontului pentru ecrane mari */
    }

    .underlineyellow,
    .underlineblack {
        width: 320px;
        /* Ajustează lungimea subliniilor pentru ecrane mari */
    }
}