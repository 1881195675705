.section_formTravel_buy{
  width: 100%;
  min-height: 550px;
  height: auto;
  padding-top: 120px;

  background-color: #EFECEF;
  text-align: right;
  font: Alegreya;
  overflow: hidden;
  padding-bottom: 70px;
  
}

.sageata_travel{
  width: 25px;
    height: auto;
}

.travel_hand{
height: auto;

  margin-bottom: -90px;
  margin-right: -60px;
}

.travel_sticky{
  position: fixed;
    top: 250px;
}

.travel_sticky:hover {
  cursor: pointer;
}

.display-none{
  display: none;
}

.rca_sticky{
  z-index: 99;
}

.containerTravel_buy__form{
  position: relative;
  
  z-index: 99;
 
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BDCCD5;
  border-radius: 91px;
  opacity: 1;
  min-height: 657px;
  
}
.sectionTravel_buy__left{
    padding-left: 20px !important;
    text-align: left !important;
}
.sectionTravel_buy__left input{
  top: 626px;
    left: 170px;
} 

.sectionTravel_buy__left label{
  font: normal normal normal 16px/26px Alegreya !important;
}


/* .sectionTravel_buy__left select{
  top: 359px;
  left: 770px;
  width: 280px;
  height: 35px;
  
  background: #FFFFFF 0% 0% no-repeat padding-box !important;;
  border: 1px solid #BDCCD5 ;
  border-radius: 10px !important;;
  opacity: 1;
} */

/* .sectionTravel_buy__left_red select {
  top: 359px;
  left: 770px;
  width: 280px;
  height: 35px;

  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  ;
  border: 1px solid #fb2626;
  border-radius: 10px !important;
  ;
  opacity: 1;
} */

.sectionRCA__left_title1{
    text-align: left;
      font: normal normal bold 34px/49px Alegreya !important;
      letter-spacing: 0px;
}
.sectionRCA__left_title2{
  margin-top: 25px !important;
  text-align: left;
    font: normal normal bold 18px/24px Alegreya !important;
    color: #000000 !important;
    opacity: 1;
}

.sectionTravel_buy__right p{
  margin-top: 77px;
  display: inline-block;
    text-align: right;
    
  
}


.circle {
  padding-left: 10px;
  margin: 10px;
  width: 18px;
    height: 18px;
  background-color: #EFECEF;
  border: 1px solid #EFECEF;
  border-radius: 50%;
}

.circle_transparent{
  padding-left: 10px;
  margin: 10px;
  width: 18px;
    height: 18px;
  background-color: white;
  border: 1px solid #EFECEF;
  border-radius: 50%;
}

.circle_transparent_riglt{
  margin: 10px;
  width: 18px;
    height: 18px;
  background-color: white;
  border: 1px solid #BDCCD5;
  border-radius: 50%;
}

.img_BuyTravel{
  width: 303px;
    height: 51px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.sectionRCA_buy_center{
  text-align: center;
}

.title_rca{
    text-align: left !important;
    font: normal normal normal 16px/26px Alegreya !important;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
}
.sub_title_Travel_buy,
.sub_title_Travel_buy_red{
  margin-top: 17px;
  text-align: left !important;
    font: normal normal normal 16px/26px Alegreya !important;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
  display: inline-block;
    text-align: left;
}

.sectionTravel__left_title2 {
  margin-top: 25px !important;
  text-align: left;
  font: normal normal bold 18px/24px Alegreya !important;
  color: #000000 !important;
  opacity: 1;
}

.subsub_title_Travel_buy{
  text-align: left;
  font: italic normal normal 14px/20px Alegreya !important;
  color: #000000;
}

.subtitle_Travel_spatiu{
  padding-top: 20px;
}
.sub_title_Travel_buy input{
  background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #3E3E3E;
    border-radius: 10px;
  /* UI Properties */
}

.sub_title_Travel_buy_red input {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #fb2626 !important;
  border-radius: 10px;
}

.sub_title_Travel_buy1{
  text-align: left !important;
    font: normal normal normal 16px/26px Alegreya !important;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    display: inline-block;
    text-align: left;
}

.sub_title_Travel_buy1 input {
  display: inline-block;
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #BDCCD5 !important;
  border-radius: 91px !important;
  opacity: 1 !important;
}

.sub_title_Travel_buy select {
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #BDCCD5 !important;
  border-radius: 91px !important;
  opacity: 1 !important;
}

.sectionTravel_buy__form1{
  margin-top: 26px !important;
}


.sub_titleForm_Travel_buy{
  margin-top: 40px;
  margin-left: 55px;
    text-align: left;
      font: normal normal normal 16px/26px Alegreya;
      letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}



.sectionTravel_buy__left1 label{
  margin-top: 62px !important;
  margin-bottom: 34px !important;
}

.sectionTravel_buy__form_back p{
  text-align: left !important;
  display: inline-block !important;
}

.sectionTravel_buy__form_back{
  margin-top: 30px;
  margin-left: 10px;
}

.sectionTravel_buy_button_back{
  font: normal normal bold 16px/22px Alegreya;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.sectionRCA__left_title12 {
  margin-top: 10px !important;
  text-align: left !important;
  font: normal normal bold 34px/49px Alegreya !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.sectionTravel_buy__right2 p{
    margin-top: 100px;
      display: inline-block;
      text-align: right;
}

.sub_title_Travel_select_active{
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #FBBCFB !important;
  border-radius: 10px !important;
  color: #000000 !important;
  font: normal normal bold 14px/26px Muli !important;
    margin-top: 10px;
    margin-right: 28px !important;
    padding-left: 36px !important;
    padding-right: 34px !important;
}

.sub_title_Travel_select{
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #646464 !important;
    border-radius: 10px !important;
    font: normal normal normal 14px/26px Muli !important;
    color: #000000 !important;
    margin-top: 10px;
    margin-right: 28px !important;
    padding-left: 36px !important;
    padding-right: 34px !important;
}

.sub_title_rca_select_CetatenieActiv {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #FBBCFB !important;
    border-radius: 10px !important;
    color: #000000 !important;
    font: normal normal bold 14px/26px Muli !important;
    margin-right: 14px !important;
    margin-bottom: 5px;
}


.sub_title_rca_select_Cetatenie {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #3E3E3E !important;
    border-radius: 10px !important;
    color: #000000 !important;
    font: normal normal normal 14px/26px Muli !important;
    margin-right: 14px !important;
    margin-bottom: 5px;
}

.dimentionCetatenie {
  width: 83px;
  height: 34px;
}

.sectionTravel_buy_Europa {
  font: normal normal normal 10px Muli !important;
}

.sub_title_rca_select{
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #3E3E3E !important;
    border-radius: 10px !important;
    color: #000000 !important;
    font: normal normal normal 14px/26px Muli !important;
      margin-right: 28px !important;
      padding-left: 34px !important;
      padding-right: 34px !important;
}

.sectionRCA__left_subtitle2{
    margin-top: 15px !important;
      text-align: left;
      font: normal normal bold 18px/24px Alegreya !important;
      color: #000000 !important;
      opacity: 1;
      margin-left: 45px;
}

.sectionTravel__left_subtitle1 {
  margin-top: 25px !important;
  text-align: left;
  font: normal normal bold 18px/24px Alegreya !important;
  color: #000000 !important;
  opacity: 1;
}

.sectionTravel_buy__left_2{
    padding-left: 59px !important;
      text-align: left !important;
}

.sectionTravel_buy__left_2 input{
  top: 792px;
    left: 170px;
    width: 179px;
    height: 35px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #3E3E3E;
    border-radius: 10px;
    opacity: 1;
}

.sectionTravel_buy__left_mini1 input {
  top: 792px;
  left: 170px;
  width: 179px;
  height: 35px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #3E3E3E;
  border-radius: 10px;
  opacity: 1;
}
.sub_title_Travel_buy_data select,
.sub_title_Travel_buy_data input{
  top: 395px;
  left: 172px;
  width: 280px;
  height: 35px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  font: italic normal normal 14px/19px Alegreya;
  text-align: left;
  border: 1px solid #3E3E3E;
  border-radius: 10px;
  opacity: 1;
}
.sub_title_Travel_buy_data_red select,
.sub_title_Travel_buy_data_red input {
  top: 395px;
  left: 172px;
  width: 280px;
  height: 35px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  font: italic normal normal 14px/19px Alegreya;
  text-align: left;
  border: 1px solid #fb2626 !important;
  border-radius: 10px;
  opacity: 1;
}

.sectionRCA__left_check1{
  margin-top: 10px;
    text-align: left;
      font: normal normal normal 14px/26px Alegreya;
      letter-spacing: 0px;
      color: #000000;
      margin-left: 45px !important;
      text-align: left !important;
}

.sectionRCA__left_check{
  text-align: left;
    font: normal normal normal 14px/26px Alegreya;
    letter-spacing: 0px;
    color: #000000;
  margin-left: 45px !important;
  text-align: left !important;
}

.non_display{
  display: none;
}

.sectionTravel_buy__button{
  margin-left: 45px;
  text-align: left;

}
.sectionTravel_buy__button_CI {
  margin-top: 20PX;
  margin-left: 45px;
    text-align: left;
}

.Travel_buy_add_calator{
  font: normal normal bold 18px/26px Alegreya !important;
    letter-spacing: 0px;
    margin-top: 30px;
}

.Travel_buy_add_calator img{
  margin-right: 10px;
}

.add_new_Travel_bay{
  background: #E7D6E7 0% 0% no-repeat padding-box;
  border-radius: 23px;
  margin-top: -30px;
  padding-bottom: 30px;
  width: 337px;
  height: 385px;
  height: auto;
}

.travelPolita_img{
  margin-left: 50px;
  margin-top: 20px;
  margin-bottom: 35px;
  width: 303px;
  height: 51px;
}

.travelNext_img{
  margin-left: 35px;
  margin-top: 45px;
  margin-bottom: 60px;
  width: 243px;
  height: 51px;
}

.add_new_Travel_bay p{
  font: normal normal bold 16px/26px Alegreya;
    letter-spacing: 0px;
    color: #000000;
    margin-left: 33px ;
    padding-top: 27px;
}
.add_new_Travel_bay label{
font: normal normal 900 14px/20px Alegreya !important;
}


.travelBagaj_img{
  margin-top: -40px;
  margin-left: 212px;
  text-align: right;
  width: 125px;
  height: 138px;
}


.add_new_Travel_bay_rutiera{
  letter-spacing: 0px;
  color: #000000;
  text-align: left !important;
  margin-left: 33px;
      
}


.add_new_Travel_bay_rutiera input {
  width: 19px;
  height: 19px;
  margin-right: 10px;
}

.subtitle_add_new_Travel_bay_rutiera a,
.subtitle_add_new_Travel_bay_rutiera{
  margin-left: 62px !important;
  font: normal normal normal 12px/16px Alegreya !important;
  padding-top: 0px !important;
}


.sub_title_Travel_buy_optiuni_red,
.sub_title_Travel_buy_optiuni{
  margin-left: 62px;
  font: normal normal normal 12px/26px Alegreya !important;
  margin-bottom: 15px;
}

.sub_title_Travel_buy_optiuni input{
  width: 226px;
  height: 30px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #3E3E3E;
  border-radius: 10px;
}

.sub_title_Travel_buy_optiuni_red input {
  width: 226px;
  height: 30px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #fb2626;
  border-radius: 10px;
}

.sectionTravel_buy__right_img img {
  text-align: right !important;
  margin-left: 35px;
  margin-top: -50px !important;


}

.Travel_sum_oferta p{
  font: normal normal 300 14px/18px Muli !important; 
  color: #999999;
}

.Travel_sum_oferta h1 {
  font: normal normal 300 24px/30px Muli !important;
  margin-top: -20px;
  color: #999999;
}

.Travel_sum_detalii{
  background-color: #F7F4F7;
  /* text-align: left !important; */

}
.Travel_sum_detalii img{
  margin-left: 5px;
  margin-bottom: 5px;
}

.eurolifeFFH{
  width: 155px;
  height: auto;
}



.Travel_sum_detalii h1 {
  text-align: left;
  margin-left: 14px;
    font: normal normal bold 20px/27px Alegreya;
}

.Travel_sum_detaliis {
  text-align: left;
  margin-left: 14px;
  font: normal normal normal 16px/20px Muli;
}

.Travel_sum_detaliiStanga {
  text-align: right !important;
  margin-right: 14px;
  font: normal normal normal 16px/20px Muli;
}

.Travel_sum_detalii p {
  font: normal normal bold 20px/25px Muli;
  
}

.Travel_sum_detalii_label{
  margin-top: 10px;
  margin-bottom: 5px;
  font: normal normal bold 16px/22px Alegreya;
  margin-left: 14px !important;
  display: block;
}

.Travel_sum_detalii_label{
  text-decoration: none;
  font: normal normal bold 16px/22px Alegreya;
  color: #000000;
}

.Travel_sum_detalii_label:hover {
  text-decoration: none;
  font: normal normal bold 16px/22px Alegreya;
  color: #000000;
}


.sectionTravel_buy__left_sec{
  display: flex;
  justify-content: space-between;

  margin-top: 16px;
  
}

.sectionTravel_buy__left_sec p {
  font: normal normal bold 20px/25px Muli;

}
.sectionTravel_buy__right_sec p{
  text-align: right !important;
}
.sectionTravel_buy__left_ {
  padding-left: 59px !important;
  text-align: left !important;
}

.sectionTravel_buy__leftAdd,
.sectionTravel_buy__left {
  padding-left: 59px !important;
  text-align: left !important;
}

.sectionTreval_buy__Polita_subtitle {
  font: normal normal bold 18px/24px Alegreya !important;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 15px;
}

.sectionTreval_buy__Polita_subtitle input{
  background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EFECEF;
    border-radius: 10px;

}
.sectionTravel_buy__Polita_info{
text-align: left;
  font: normal normal normal 18px/24px Alegreya !important;
  letter-spacing: 0px;
  color: #000000;
}

.sectionTravel_buy__Polita_right {
  padding: 26px !important;
  text-align: left !important;

  background-color: #EFECEF;

  margin: 15px;

}



.sectionTravel_buy__left_border { 
  border-right: 1px solid #BDCCD5;
}

.sectionTravel_buy__Polita_detalii {
  text-align: left !important;
  font: normal normal bold 32px/44px Alegreya !important;
  letter-spacing: 0px;
  color: #A37AA3;
  margin-left: 0px !important;
}

.sectionTravel_buy_Polita_line {
  border-bottom: 1px solid #BDCCD5;
  margin-top: 40px !important;
  width: 100% !important;
  text-align: center !important;
  margin: auto;
}

.sectionTravel_buy__Polita_subtitle {
  font: normal normal bold 18px/24px Alegreya !important;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 15px;
}


.sectionTravel__left_check {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
  font: normal normal normal 16px/20px Alegreya;
  letter-spacing: 0px;
  color: #000000;
  margin-left: 45px !important;
  text-align: left !important;
}

.sectionTravel__left_check1_red label,
.sectionTravel__left_check1 label{

  font: normal normal normal 16px/20px Alegreya;
  letter-spacing: 0px;
  color: #000000;
  text-align: left !important;
  display: inline-flex;
  text-align: left;
  margin-top: -20px;
}

.sectionTravel__left_check1_red label{
  color: #fb2626;
}

.sectionTravel__left_check1_red input,
.sectionTravel__left_check1 input {
  margin-top: 5px;
  height: 15px;
  width: auto;
  margin-right: 5px;
  
}

.scrollable-textbox {
  background-color: transparent;
  /* border: none; */
  resize: none;
  /* Dezactivează redimensionarea manuală a textarea */
  overflow: auto;
  /* Activează bara de derulare atunci când textul depășește dimensiunile textarea */
}

.add_new_Travel_bay label,
.sectionTravel__left_check label {
  display: inline-block !important;
  margin-right: 10px;
}

.add_new_Travel_bay label input,
.sectionTravel__left_check label input {
  display: inline-block !important;
  margin-right: 5px;
}

.img_travel_alegeFinal{
  /* width: 200px;
  height: auto; */
  display: flex;
    align-items: center;
    justify-content: center;
}

.loading_gf{
  text-align: center;
}
.loading_gf img{
    width: 120px;
    height: auto;
}

@media screen and (max-width: 1290px) {
  .img_travel_alege{
    /* width: 207px;
    height: 37px; */
  }
}

@media screen and (max-width: 1200px) {
  .sectionTravel_buy__right_img img {

    margin-top: 0px !important;

  }
    .img_travel_alege {
      /* width: 240px;
      height: 37px; */
    } 

  .add_new_Travel_bay {
    margin-top: 20px;

    width: 100%;
  }

  .sectionTravel_buy__left select  {
    width: 230px;
  }

  .travelBagaj_img{
    margin-left: 75%;
  }
}

@media screen and (max-width: 990px) {
  .sectionTravel_buy__left{
    text-align: left !important;
  }
}

@media screen and (max-width: 767px) {
  .sectionTravel_buy__left{
    padding-left: 20px !important;
  }
  .sub_titleForm_Travel_buy, 
  .sectionTravel_buy__button, 
  .sectionRCA__left_subtitle2 {
    margin-left: 20px;
  }
  .sectionTravel_buy__left_sec{
    justify-content: center ;
  }

  .Travel_sum_detalii h1,
  .Travel_sum_detalii {
      background-color: #F7F4F7;
      text-align: center !important;
      margin-right: 30px;
  
    }

  .img_travel_alege {
      /* width: 180px;
      height: 37px; */
    }

  .sectionTravel_buy__left select {
    width: 280px;
  }

  .add_new_Travel_bay{
    margin-left: 5px;
    margin-right: 5px;
  }
  .sectionTravel_buy__right2 p{
      margin-top: 0px;

    }
.sectionRCA_buy__left1 {

  text-align: left;
}
.sectionTravel_buy__leftAdd{
  padding-left: 15px !important;
  padding-right: 20px !important;
}


}

@media screen and (max-width: 500px) {
  .sectionTravel_buy__left {
    padding-left: 10px !important;
  }
}

