.section_formRCA_buy{
  width: 100%;
  min-height: 1050px;
  height: auto;
  padding-top: 90px;

  background-color: #EEF5FC;
  text-align: right;
  font: Alegreya;
  overflow: hidden;
  padding-bottom: 100px;
  
}

.sageata_rca{
  width: 25px;
    height: auto;
}

.containerRCA_rca_hand{
  margin: 0px;


}

.rca_hand {
  height: auto;

 margin-bottom: -90px;
 margin-right: -60px;


}

.rca_sticky {
  position: fixed;
  top: 40%;
  /* Distanța de sus */
  right: 20px;
  /* Distanța de dreapta */
  width: 80px;
  /* Lățimea imaginii */
  height: auto;
  cursor: pointer;
}

.rca_sticky:hover {
  cursor: pointer;
}

.display-none{
  display: none !important;
}

label {
  display: block;
}

label input {
  display: block;
}

label select {
  display: block;

}


.containerRCA_buy__form{
  position: relative;
padding-right: 100px;
  z-index: 5;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BDCCD5;
  border-radius: 91px;
  opacity: 1;
  min-height: 817px;

  
}

.sectionRCA_buy__left{
  padding-left: 59px !important;
  text-align: left !important;

}

.sectionRCA_buy__left_oferte{
  padding-left: 59px !important;
  text-align: left !important;
}

.sectionRCA_buy__left_oferte label{
  padding-left: 30px !important;
}

.sectionRCA_buy__left_oferteClasa{
  display: flex;
    align-items: center;
    justify-content: left;
}
.sectionRCA_buy__left_oferteImg{
  display: flex;
  align-items: center;
  justify-content: center;
}
.sectionRCA_buy__left input{
  top: 626px;
    left: 170px;
    width: 280px;
    height: 35px;
}

.sectionRCA_buy__left select{
top: 359px;
  left: 770px;
  width: 280px;
  height: 35px;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;;
    border: 1px solid #BDCCD5 !important;;
    border-radius: 91px !important;;
    opacity: 1;
}
.sectionRCA__left_title1{
    text-align: left !important;
    font: normal normal bold 34px/49px Alegreya !important;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 70px !important;

}
.sectionRCA__left_title2{
  margin-top: 25px !important;
  text-align: left;
    font: normal normal bold 18px/24px Alegreya !important;
    color: #000000 !important;
    opacity: 1;
}

.sectionRCA_buy__right p{
  margin-top: 77px;
  display: inline-block;
    text-align: right;
    
  
}


.circleRCA {
  padding-left: 10px;
  margin: 10px;
  width: 18px !important;
    height: 18px !important;
  background-color: #BDCCD5 !important;
  border: 1px solid #BDCCD5 !important;
  border-radius: 50%;
  
}

.circle_transparentRCA{
  padding-left: 10px;
  margin: 10px;
  width: 18px !important;
    height: 18px !important;
  background-color: white;
  border: 1px solid #BDCCD5;
  border-radius: 50%;
}

.circle_transparent_riglt{
  margin: 10px;
  width: 12px;
  height: 12px;
  background-color: white;
  border: 1px solid #BDCCD5;
  border-radius: 50%;
}

.title_rca{
    text-align: left !important;
    font: normal normal normal 16px/26px Alegreya !important;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
}

.sub_title_rca_FizicJuridic{
  margin-top: 43px;
}

.sub_title_rca_buy_blue,
.sub_title_rca_buy{
  margin-top: 17px;
  text-align: left !important;
    font: normal normal normal 16px/26px Alegreya !important;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
  display: inline-block;
    text-align: left;
}

.sub_title_rca_buy input{
  background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #66B6FF;
    border-radius: 10px;
  /* UI Properties */
}

.sub_title_rca_buy_gri {
  margin-top: 17px;
  text-align: left !important;
  font: normal normal normal 16px/26px Alegreya !important;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
  display: inline-block;
  text-align: left;
}

.sub_title_rca_buy_gri input {
  background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #3E3E3E;
    border-radius: 10px;
  /* UI Properties */
}

.sub_title_rca_buy_red {
  margin-top: 17px;
  text-align: left !important;
  font: normal normal normal 16px/26px Alegreya !important;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
  display: inline-block;
  text-align: left;
}

.sub_title_rca_buy_red input {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid red !important;
  border-radius: 10px;
  /* UI Properties */
}



.sub_title_rca_buy1{
  text-align: left !important;
    font: normal normal normal 16px/26px Alegreya !important;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    display: inline-block;
    text-align: left;
}

.sub_title_rca_buy1 input {
  display: inline-block;
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #BDCCD5 !important;
  border-radius: 91px !important;
  opacity: 1 !important;
}

.sub_title_rca_buy select {
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #BDCCD5 !important;
  border-radius: 10px !important;
  opacity: 1 !important;
}

.sub_title_rca_buy_blue select{
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #66B6FF !important;
    border-radius: 10px !important;
    opacity: 1 !important;
}


.sectionRCA_buy__form{

}

.sub_titleForm_rca_buy{
  margin-top: 40px;
  margin-left: 58px;
    text-align: left;
    font: normal normal bold 16px/26px Alegreya;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.sectionRCA_buy__right_img img{
  text-align: right !important;
  margin-left: 35px;
  margin-top: -160px !important;
  
  
}


.sectionRCA_buy__left1 label{
  margin-top: 62px !important;
  margin-bottom: 34px !important;
}

.sectionRCA_buy__form_back p{
  text-align: left !important;
  display: inline-block !important;
}

.sectionRCA_buy__form_back{
  margin-top: 20px;
  margin-left: 10px;
}

.sectionRCA_buy_button_back{
  font: normal normal bold 16px/22px Alegreya;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    cursor: default;
}

.sectionRCA_buy_button_back:hover{
  font: normal normal bold 16px/22px Alegreya;

  color: #000000;

  cursor: pointer !important;
}

.sectionRCA__left_title12 {
  text-align: left !important;
  font: normal normal bold 34px/49px Alegreya !important;
  color: #000000;

  margin-top: 0px !important;
}

.sectionRCA_buy__right2 p{
  
    margin-top: 90px;
      display: inline-block;
      text-align: right;
}

.sub_title_rca_select_active{
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #66B6FF !important;
  border-radius: 10px !important;
  color: #000000 !important;
  font: normal normal bold 14px/26px Muli !important;
  margin-right: 28px !important;
  margin-bottom: 5px;
  /* margin-right: 28px !important;
  padding-left: 34px !important;
  padding-right: 34px !important; */
  
}

.dimentionFJ{
  width: 171px;
    height: 35px;
}

.dimentionDN {
  width: 87px;
    height: 34px;
}

.sub_title_rca_select{
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #3E3E3E !important;
  border-radius: 10px !important;
  color: #000000 !important;
  font: normal normal normal 14px/26px Muli !important;
  margin-right: 28px !important;
  margin-bottom: 5px;
  /*padding-left: 34px !important;
  padding-right: 34px !important; */

}

.sectionRCA__left_subtitle2{
  margin-top: 25px !important;
  text-align: left;
  font: normal normal bold 18px/24px Alegreya !important;
  color: #000000 !important;
  margin-left: 45px;
}

.sectionRCA_buy__left_2{
    padding-left: 59px !important;
      text-align: left !important;
}

.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid black;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 9999;
  text-align: center;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  z-index: 9998;
}

.popup-logo{
  margin-top: 20px;
  margin-bottom: 10px;
}

.popup-text a {
  font: normal normal bold 16px/26px Alegreya;
  color: #000000;
}

.popup-text a:hover {
  font: normal normal bold 16px/26px Alegreya;
  color: #000000;
}

.popup-text {
  font: normal normal normal 16px/26px Alegreya;
  color: #000000;
}

.popup-button{
  background-color: #FFD506;
  width: 103px;
    height: 31px;
    border: 1px solid #FFD506;
      border-radius: 10px;
}

.sectionRCA_buy__left_2 input{
  top: 792px;
    left: 170px;
    width: 179px;
    height: 35px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #3E3E3E;
    border-radius: 10px;
    opacity: 1;
}



.sub_title_rca_buy_data input{
    top: 980px;
      left: 170px;
      width: 179px;
      height: 35px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #3E3E3E;
      border-radius: 10px;
      opacity: 1;


}

.sub_title_rca_buy_data  a{
  font: normal normal normal 16px/20px Alegreya;
    color: #353535;

}

.sub_title_rca_buy_data a:hover {
  font: normal normal normal 16px/20px Alegreya;
  color: #353535;
}

.sectionRCA__left_check1{
  margin-top: 38px;
    text-align: left;
      font: normal normal normal 16px/20px Alegreya;
      letter-spacing: 0px;
      color: #000000;
      margin-left: 45px;
      text-align: left !important;
}
.sectionRCA__left_check label,
.sectionRCA__left_check1 label{
  display: inline-block !important;
  margin-right: 10px;
}

.sectionRCA__left_check label input,
.sectionRCA__left_check1 label input{
  display: inline-block !important;
  margin-right: 5px;
}


.sectionRCA__left_check label input[type="checkbox"],
.sectionRCA__left_check1 label input[type="checkbox"] {
  border: 1px solid #272727;
    border-radius: 5px;
}


.sectionRCA__left_check a,
.sectionRCA__left_check1 a {
  font: normal normal bold 14px/26px Alegreya;
  color: #000000;
}


.sectionRCA__left_check a:hover,
.sectionRCA__left_check1 a:hover {
  font: normal normal bold 14px/26px Alegreya;
  color: #000000;
}



.sectionRCA__left_check{
  text-align: left;
    font: normal normal normal 16px/20px Alegreya;
    letter-spacing: 0px;
    color: #000000;
  margin-left: 45px;
  text-align: left !important;
}

.rca_info_a{
  font: normal normal bold 12px/26px Alegreya;

    color: #353535;
}
.rca_info_a:hover {
  font: normal normal bold 12px/26px Alegreya;

  color: #353535;
}

.non_display{
  display: none !important;
}
.sectionRCA_buy__Oferte_Pret,
.sectionRCA_buy__Oferte_subtitle{
  text-align: left !important;
  font: normal normal bold 18px/24px Alegreya;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  display: block;
}

.sectionRCA_buy__Oferte_Clasa{
  text-align: left !important;
  justify-content: left !important;
  align-items: left;
  font: normal normal normal 16px/22px Alegreya;
  color: #000000;
}

.sectionRCA_buy__Oferte_subtitleInfo{
  margin-left: -110px;
}

.sectionRCA_buy__Oferte_img{
  margin-top: 12px;
  margin-left: 34px;
}

.sub_title_rca_select_active_tarif{
  margin-top: 17px;
  margin-right: 15px;
  text-align: left;
    font: normal normal bold 14px Muli !important;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
      border: 1px solid #66B6FF !important;
      border-radius: 10px;
      opacity: 1;
}

.sub_title_rca_select_tarif {
  margin-right: 15px;
  margin-top: 17px;
  text-align: left;
    font: normal normal normal 14px Muli !important;;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
      border: 1px solid #3E3E3E !important;
      border-radius: 10px;
      opacity: 0.5;
}

.sectionRCA_buy__Oferte_subtitle_luni{
    margin-top: 20px;
    font: normal normal bold 18px/24px Alegreya !important;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.sub_title_rca_buy_button{
  margin-top: 0px !important;
}

.sectionRCA_buy__button{
  margin-top: 20px;
}

.sectionRCA_buy__Loading{
  font: normal normal bold 60px/30px Alegreya !important;
  padding-top: 90px !important;
  margin-bottom: 200px !important;
  align-content: center !important;
  text-align: center !important;
}

.sectionRCA_buy__oferte{

  margin-top: 30px !important;
}

.sectionRCA_buy__oferte_border hr{
  border-bottom: 1px solid #BDCCD5;
  margin-top: 40px !important;
      width: 95% !important;
      text-align: center !important;
      margin: auto;
}

.sectionRCA_buy__Oferte_subtitle_luni{
  margin-left: 20px;
}

.sub_title_rca_buy_next{
  margin-left: 33px !important;

  float: left;
}

.sub_title_rca_buy_next:hover {
 
  cursor: pointer;
}


.sub_title_rca_select_active_tarif_12luni{
    margin-top: 17px;
      margin-right: 15px;
      text-align: left;
      font: normal normal bold 14px Muli !important;
      background: #FFFFFF 0% 0% no-repeat padding-box !important;
        border: 1px solid #BDCCD5;
        color: #BDCCD5 !important;
        border-radius: 10px;
}

.sectionRCA_buy__Polita_info{
  text-align: left;
  font: normal normal normal 18px/24px Alegreya !important;
  letter-spacing: 0px;
  color: #000000;
}

.sectionRCA_buy__Polita_subtitle{
  font: normal normal bold 18px/24px Alegreya !important;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 15px ;
}

.sectionRCA_buy__Polita_detalii{
  text-align: left !important;
  font: normal normal bold 32px/44px Alegreya;
  letter-spacing: 0px;
  color: #1081E9;
  margin-left: 0px !important;
}

.sectionRCA_buy__left_border{
  border-right: 1px solid #BDCCD5;
}

.sectionRCA_buy__Oferte_img_border{
width: 105px;
  height: 46px;
  border: 1px solid #BDCCD5;
}
.sectionRCA_buy__left_det_img{
  text-align: right;
}

.sectionRCA_buy__Polita_right{
  padding: 26px !important;
  text-align: left !important;
  background-color: #EEF5FC;

}

.sectionRCA_buy__Polita_background{
  background-color: #FFFFFF !important;
}

.sectionRCA_buy_Polita_line {
  border-bottom: 1px solid #BDCCD5;
  margin-top: 40px !important;
  width: 100% !important;
  text-align: center !important;
  margin: auto;
}

.sectionRCA_buy__Polita_rigt{
  text-align: right !important;
  float: right !important;
}

.sectionRCA_buy__Polita_subtitle_right {
  font: normal normal bold 18px/24px Alegreya !important;
  float: right !important;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 15px;
}

.sectionRCA_buy__Polita_title_subsol{
  margin-top: 20px;
  margin-bottom: 35px;
  text-align: center;
    font: normal normal normal 16px/22px Alegreya !important;
    letter-spacing: 0px;
    color: #000000;
}

.sectionRCA_buy__Oferte_img_ass{
  width: 105px;
    height: 46px;
}
.sectionRCA_buy__Polita_title_subsol p{
margin-bottom: 35px;
}

.AvertizmentRca{
  text-align: center;
}

.AvertizmentRca label{
  font: normal normal bold 16px Alegreya;
}

.errorPage_RCAbuy{
  text-align: left !important;

  margin-left: 45px;
 margin-bottom: 2px;
  color: crimson;
}

.errorPage_RCAbuy_polita{
  color: crimson;
  margin-bottom: 0px;
}
 
.img_scale {
  
width: 303px;
  height: auto;
}
.img_scale:hover {

  cursor: pointer !important;
  
}

.LoadingImg{
  width: 100%;
  height: auto;
  object-fit: cover;

}

.floating-image {
 
  width: 200px;
  height: auto;
  overflow: hidden;
  position: relative;
  animation-name: float;
  text-align: center;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  display: flex;
  margin: auto;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.floating-image img {
  width: 100%;
  height: auto;
}

.alegePlata:hover{
  cursor: pointer !important;
}


@media screen and (max-width: 992px) {

  .sectionRCA_buy__Oferte_subtitleInfo {
      margin-left: -30px;
    }

}


@media screen and (max-width: 1200px) {

  .sectionRCA_buy__oferte {
  
      margin-top: 30px !important;
    }

  .sectionRCA_buy__right_img{
    margin-top: 90px !important;
    margin-left: 0px;
  }

}

@media screen and (max-width: 767px) {
  .rca_hand,
  .travel_hand {
      height: auto;
      margin-bottom: -70px;
      margin-right: -20px;
    }

  /* .rca_sticky {
      position: fixed;
      top: 50%;

      right: 0px;
      left: 5px;


    } */

  .sectionRCA_buy__left_oferte label {
      padding-left: 0px !important;
    }

  .sectionRCA_buy__Oferte_Pret,
  .sectionRCA_buy__Oferte_subtitle_luni{
    text-align: center !important;
    align-items: center;
      justify-content: center;
    display: block;
    margin-left: -5px;
  }
  .sectionRCA_buy__left_oferteClasa {
      display: flex;
      align-items: center;
      justify-content: center;
    }

  .sectionRCA_buy__Oferte_Clasa {
      text-align: center !important;
      justify-content: center !important;
      align-items: center !important;
      font: normal normal normal 16px/22px Alegreya;
      color: #000000;
    }

  .sectionRCA_buy__Oferte_subtitleInfo {
      margin-left: 0px;
      margin-top: 20px;
    }

  .sectionRCA_buy__right_img {

    text-align: center;

  }


  .sectionRCA_buy__left_oferte{
    text-align: center !important;
      justify-content: center;
  }

    .sectionRCA_buy__right2 p {
  
      margin-top: 50px;

    }

    .sectionRCA_buy__Oferte_subtitle_luni {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .sectionRCA_buy__left_det{
      text-align: center !important;

    }

    .sectionRCA_buy__left_det_img{
      text-align: center;
    }


}

@media screen and (max-width: 450px) {
  .sectionRCA__left_check1,
  .sectionRCA__left_check{
    margin-left: 20px !important;
  }
  .sectionRCA__left_subtitle2{
    margin-left: 20px !important;
    padding-left: 0px;
  }
  .sectionRCA_buy__left_2{
    padding-left: 20px !important;
  }
  .sub_title_rca_buy_next {
      margin-left: 5px !important;
    }
  .sectionRCA_buy__left {
    padding-left: 20px !important;

  }
  .errorPage_RCAbuy {
    margin-left: 20px !important;
  }
}