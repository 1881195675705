.section3{
  min-height: 412px;
  height: auto;
  display: table;

  width: 100%;
  text-align: center;
  background-color: #66B6FF33;
  margin: 0px;
  padding: 0px;
  font: Alegreya;
  overflow: hidden;
  
}

.section3__lf{
  
  background: #FFF2E4
}

.section3__title{
  padding-top: 37.3px;
  font: normal normal bold 45px/57px Amatic SC;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-bottom:  32px;
  }

.section3__subtitle {
  font: normal normal bold 18px/27px Alegreya;
  letter-spacing: 0px;
  color: #202020;
  opacity: 1;
  padding-top:  35px;
  padding-bottom:  56px;

  }

  @media screen and (max-width: 968px) {

    .section3__title{
      font: normal normal bold 36px Amatic SC;
    }

    .section3__subtitle{
      font: normal normal bold 14px Alegreya;
    }

    .senfluaLife{
      max-width: 70%;
    }

    .senfluaTHINGS{
      max-width: 70%;
    }
  
  
  }

