
 .sectionLocuinte{
    width: 100%;
    min-height: 900px;
    height: auto;
    padding-top: 90px;

    background-color: #6689FF;
    font: Alegreya;
    overflow: hidden;
    padding-bottom: 100px;
 }

 .containerLocuinte{ 
    width: 1100px;
 }

 .buttonTextLocuinte {
     font: normal normal bold 16px/22px Alegreya;
 }

 .sectionHP_Bunuri {
     background-color: #E0E7FF;
     padding-top: 20px;
     border-radius: 16px;
     padding-top: 30px;
     padding-left: 30px;
     padding-bottom: 20px;
     padding-right: 30px;
 }

 .sectionHP_BunuriSpace {
     padding-left: 5px;
     padding-right: 5px;

 }

 .colLocuinteLeft{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D9D4D9;
    border-radius: 26px;
    padding: 26px;
 }

 .colLocuinteRight{
    background-color: #E0E7FF;
    padding: 27px;
    border: 1px solid #D9D4D9;
    border-radius: 26px;
 }

.locuinteTitle{
    font: normal normal bold 25px Alegreya;
    color: #000000;
}

.locuinteText {
    font: normal normal normal 14px/26px Alegreya;
    color: #0B0B0B;
}

.locuinteTextDr {
    font: normal normal bold 14px/26px Alegreya;
    color: #0B0B0B;
    
    margin-left: 10px;
}

.buttonDownloadeLocuinteIntro {
    margin-left: 10px;
    background-color: rgba(240, 248, 255, 0);
    border: 0;
    text-decoration: underline;
    letter-spacing: 0px;
    color: #000000;
    font: normal normal bold 14px/26px Alegreya;
    text-align: left;
}

.locuinteSubTitle{
    font: normal normal normal 20px Alegreya;
    color: #000000;
}

.locuinteBtnStart{
    display: flex;
    align-items: center;
    justify-content: center;
    
}



.detaliiRow {
    background-color: #E0E7FF;
    height: auto;
    /* text-align: center; */

    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 26px;
    margin-top: 30px;
    padding-left: 30px;

}

.detaliiRowFinal {
    background-color: #E0E7FF;
    height: auto;
    text-align: left;


    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 26px;
    margin-top: 30px;
}

.buttonLocuinte {
    background: #FFFFFF;
    border: 1px solid #646464;
    width: 163px;
    font: normal normal normal 14px/26px Muli;
    color: #3E3E3E;
    margin: 0px;
    text-align: center;
    border-radius: 10px;
}

.buttonLocuinteBlue{
    background: #FFFFFF;
    border: 1px solid #6689FF;
    width: 163px;
    font: normal normal normal 14px/26px Muli;
    color: #3E3E3E;
    margin: 0px;
    text-align: center;
    border-radius: 10px;
}

.detaliiMiniTitle {
    text-align: left !important;
    font: normal normal 900 22px/49px Alegreya;

}

.detaliiMiniTitle3red,
.detaliiMiniTitle3{
    text-align: center !important;
        font: normal normal 900 22px/49px Alegreya;

    align-items: center;
}

.detaliiMiniTitle4red,
.detaliiMiniTitle4{
    text-align: center;
}

.centered-select {
    margin-top: 10px;
    display: block;
    margin: 0 auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    /* font: normal normal normal 10px/54px Alegreya; */
    /* Aceasta linie asigură centrarea pe orizontală */
    padding: 0px;

}

/* .small-text {
    font-size: 12px !important;
} 
*/

.centered-select option{
    font: normal normal normal 20px/54px Alegreya;
    width: 114px;
}
.detaliiMiniTitleCodPostalselectRed,
.detaliiMiniTitleCodPostalselect,
.detaliiMiniTitle3red,
.detaliiMiniTitle2red,
.detaliiMiniTitle2red,
.detaliiMiniTitle4red,
.detaliiMiniTitle4,
.detaliiMiniTitle3,
.detaliiMiniTitle2 {
    font: normal normal bold 22px Alegreya;
    text-align: center;
    /* margin-left: 50px; */
    margin-top: 10px;
}


.detaliiAdresaFinalRed,
.detaliiAdresaFinal{
    font: normal normal normal 16px/26px Alegreya;
    text-align: center;
    
}

.detaliiAdresaFinalCesionariRed,
.detaliiAdresaFinalCesionari {
    font: normal normal normal 16px/26px Alegreya;
    text-align: left;

}

.detaliiAdresaFinalRed input::placeholder,
.detaliiAdresaFinal input::placeholder {
    font: normal normal italic 16px/26px Alegreya;

}


.detaliiAdresaFinal input {
    font: normal normal normal 16px/26px Alegreya;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #3E3E3E;
    border-radius: 10px;
    width: 150px;
    height: auto;
    margin-top: 10px;
}

.detaliiAdresaFinalRed input{
    font: normal normal normal 16px/26px Alegreya;
    background: #ff0000 0% 0% no-repeat padding-box;
    border: 1px solid #3E3E3E;
    border-radius: 10px;
    width: 150px;
    height: auto;
    margin-top: 10px;
}


.detaliiAdresaFinal select {
    border: 1px solid #BDCCD5 !important;
    border-radius: 10px !important;
    font: normal normal normal 16px/26px Alegreya;
    padding: 2px !important;
    max-width: 150px !important;
    min-width: 150px;
    height: 35px;
    color: #2D2D2D;
    margin-top: 10px;
}

.detaliiAdresaFinalRed select{
    border: 1px solid #ff0000 !important;
    border-radius: 10px !important;
    font: normal normal normal 16px/26px Alegreya;
    padding: 2px !important;
    max-width: 150px !important;
    min-width: 150px;
    height: 35px;
    color: #2D2D2D;
    margin-top: 10px;
}

.detaliiAdresaFinalCesionari select{
    border: 1px solid #BDCCD5 !important;
    border-radius: 10px !important;
    font: normal normal normal 16px/26px Alegreya;
    padding: 2px !important;
    max-width: 300px !important;
    min-width: 300px;
    height: 35px;
    color: #2D2D2D;

}

.detaliiAdresaFinalCesionariRed select{
    border: 1px solid #ff0000 !important;
    border-radius: 10px !important;
    font: normal normal normal 16px/26px Alegreya;
    padding: 2px !important;
    max-width: 300px !important;
    min-width: 300px;
    height: 35px;
    color: #2D2D2D;

}

.detaliiAdresaFinalCesionari input {
    border: 1px solid #BDCCD5 !important;
    border-radius: 10px !important;
    font: normal normal normal 16px/26px Alegreya;
    padding: 2px !important;
    max-width: 300px !important;
    min-width: 300px;
    height: 35px;
    color: #2D2D2D;

}

.detaliiAdresaFinalCesionariRed input {
    border: 1px solid #ff0000 !important;
    border-radius: 10px !important;
    font: normal normal normal 16px/26px Alegreya;
    padding: 2px !important;
    max-width: 300px !important;
    min-width: 300px;
    height: 35px;
    color: #2D2D2D;

}

.detaliiAdresaFinalRed select::content,
.detaliiAdresaFinal select::content {
    font: normal normal normal 16px/26px Alegreya;

}

.detaliiSubTitleF{
    font: normal normal bold 18px Alegreya;
}



.detaliiMiniTitle5red,
.detaliiMiniTitle5{
    font: normal normal 500 22px Alegreya;
    text-align: center;
    margin-top: 10px;
}

.containerFransiza{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.detaliiMiniTitle11 {
    font: normal normal bold 22px/38px Alegreya;
    text-align: left;
    
    margin-top: 10px;
}

.detaliiMiniTitle1Final {
    font: normal normal bold 22px/38px Alegreya;
    text-align: left;
    margin-left: 20px;
    margin-top: 10px;
}

.detaliiTitle1Final {
    font: normal normal bold 22px/38px Alegreya;
    text-align: left;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.detaliiMiniTitleFinalData,
.detaliiMiniTitleFinalDataRed,
.detaliiMiniTitleFinalRed,
.detaliiMiniTitleFinal {
    font: normal normal normal 16px/26px Alegreya;
    text-align: left;
    margin-top: 10px;
}

.detaliiMiniTitleFinalRed input,
.detaliiMiniTitleFinal input{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #3E3E3E;
    border-radius: 10px;
    margin-right: 10px;
}

.detaliiMiniTitleFinalRed input{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #ff0000;
    border-radius: 10px;
    margin-right: 10px;
}

.detaliiMiniTitleFinalData input{

    background: #FFFFFF 0% 0% no-repeat padding-box;

    border: 1px solid #3E3E3E;
    border-radius: 10px;
    margin-right: 10px;

}

.detaliiMiniTitleFinalDataRed input{

    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #fb2626 !important;
    border-radius: 10px;
    margin-right: 10px;

}


.detePersonaleCheckbox{
    margin-top: 10px;
    text-align: left;
    font: normal normal normal 14px/26px Alegreya;
    letter-spacing: 0px;
    color: #000000;
    text-align: left !important;
}

.detePersonaleCheckboxRed {
    margin-top: 10px;
    text-align: left;
    font: normal normal normal 14px/26px Alegreya;
    letter-spacing: 0px;
    color: #fb2626;
    text-align: left !important;
}

.detePersonaleCheckboxRed label,
.detePersonaleCheckbox label {
    display: inline-block !important;
    margin-right: 10px;
}
.detePersonaleCheckboxRed label input,
.detePersonaleCheckbox label input {
    display: inline-block !important;
    margin-right: 5px;
}

.detePersonaleCheckboxRed label input[type="checkbox"],
.detePersonaleCheckbox label input[type="checkbox"] {
    border: 1px solid #272727;
    border-radius: 5px;
}


.detePersonaleCheckbox a {
    font: normal normal bold 14px/26px Alegreya;
    color: #000000;
}


.sectionRCA__left_check a:hover,
.sectionRCA__left_check1 a:hover {
    font: normal normal bold 14px/26px Alegreya;
    color: #000000;
}


.detaliiMiniTitleInfo{
    font: normal normal normal 14px/26px Alegreya;
color: #3E3E3E;
}

.detaliiMiniTitle3red,
.detaliiMiniTitle3{
    margin-bottom: 20px;
}

.sectionHP_DetaliiTitle{
    text-align: left;
    font: normal normal bold 22px/49px Alegreya;
    color: #000000;
}

.sectionHP_Table{
    border-bottom: 1px solid #707070;
    margin-right: 10px;
    font: normal normal bold 16px/26px Alegreya;
    color: #6689FF;
    /* color: #707070; */
}
.sectionHP_Table1 {
    border-bottom: 1px solid #0B0B0B;
    margin-right: 10px;
    font: normal normal normal 16px/26px Alegreya;
    margin-top: 10px;
    color: #0B0B0B;
}

.butttonDatePersonale{
    background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #646464;
        border-radius: 10px;
}

.sectionHP_Tabletitle{
    text-align: center;
    
}

.sectionHP_Total {
    margin-top: 18px;
    display: flex;
    align-items: right;
}

.sectionHP_TotalTitle {
    margin: 0;
    margin-left: auto;
font: normal normal bold 22px/38px Alegreya;
}

.sectionHP_TotalSuma {
    margin: 0;
    /* Elimină marginile implicite */
    margin-left: auto;
    /* Aliniază la dreapta */
}


.sectionHP_TotalTitle {
    font: normal normal bold 22px/49px Alegreya;

}

.sectionHP_TotalSuma {
    font: normal normal bold 22px/49px Alegreya;

    color: #6689FF;


}

.detaliiButtonPlata{
    width: 158px;
    height: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #BDCCD5;
    border-radius: 26px;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12PX;
    margin-right: 10PX;
}

.detaliiButtonPlataBlue {
    width: 158px;
    height: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #6689FF;
    border-radius: 26px;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12PX;
    margin-right: 10PX;
}
.buttonLocuinteProprietara{
    width: 83px;
    height: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #BDCCD5;
    border-radius: 26px;
    text-align: center;
    padding-top: 2px;

    margin-right: 10PX;
}

.buttonLocuinteProprietaraBlue{
    width: 83px;
    height: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #6689FF;
    border-radius: 26px;
    text-align: center;
    padding-top: 2px;
    margin-right: 10PX;
}

.detaliiMiniTitle4red input::placeholder,
.detaliiMiniTitle3red input::placeholder,
.detaliiMiniTitle4 input::placeholder,
.detaliiMiniTitle5 input::placeholder,
.detaliiMiniTitle3 input::placeholder,
.detaliiMiniTitle2 input::placeholder,
.detaliiMiniTitle2red input::placeholder {
    font: normal normal italic 18px Alegreya;

}

.section_BunuriTextInput{
    width: 130px;
    height: auto;
    background-color: transparent;
    border: none;
    padding: 5px;
    border-bottom: 2px solid #BDCCD5;

    text-align: center;
}


.section_BunuriTextriscuri{
    text-align: left;
    font: normal normal normal 12px/16px Alegreya;
    color: #0B0B0B;
}

.section_BunuriTextriscuriUnder{
    text-decoration: underline;
    font: normal normal bold 12px/16px Alegreya;
    color: #0B0B0B;
}

.detaliiMiniTitle4red input,
.detaliiMiniTitle4 input,
.detaliiMiniTitle5 input,
.detaliiMiniTitle3 input,
.detaliiMiniTitle2 input,
.detaliiMiniTitle2red input {
    font: normal normal normal 18px Alegreya;
    margin-top: 10px;
    color: #2D2D2D;
}
.detaliiMiniTitleFinalRed select,
.detaliiMiniTitleFinal select,
.detaliiMiniTitle2red select,
.detaliiMiniTitle2 select{
    border: 1px solid #BDCCD5 !important;
    border-radius: 26px !important;
    font: normal normal normal 18px Alegreya;
    padding: 2px !important;
    max-width: 250px !important;
    min-width: 250px;
    color: #2D2D2D;
    margin-top: 10px;
}

.detaliiMiniTitle5 select {
    border: 1px solid #BDCCD5 !important;
    border-radius: 26px !important;
    font: normal normal normal 18px Alegreya;
    padding: 2px !important;
    max-width: 150px !important;
    min-width: 150px;
    color: #2D2D2D;
    margin-top: 10px;
}

.detaliiMiniTitleCodPostalselect select{
    border: 1px solid #BDCCD5 !important;
    border-radius: 26px !important;
    font: normal normal normal 18px Alegreya;
    padding: 2px !important;
    max-width: 150px !important;
    min-width: 150px;
    color: #2D2D2D;
    margin-top: 10px;
}

.detaliiMiniTitleCodPostalselectRed select{
    border: 1px solid #ff0000 !important;
    border-radius: 26px !important;
    font: normal normal normal 18px Alegreya;
    padding: 2px !important;
    max-width: 150px !important;
    min-width: 150px;
    color: #2D2D2D;
    margin-top: 10px;
}



.letter {
    background-color: #6689FF;
    border-radius: 50%;
    color: white;
    font:  15px Alegreya;
    width: 20px;
    height: 20px;
    margin: 5px;
}

.letterMic{
    background-color: #6689FF;
    border-radius: 50%;
    color: white;
    font: 10px Alegreya;
    width: 12px;
    height: 12px;
    margin: 5px;
}

.letterMare {
    background-color: #6689FF;
    border-radius: 50%;
    color: white;
    font: 15px Alegreya;
    width: 20px;
    height: 20px;
    margin: 5px;
    padding-left: 7px;
}

.centerColum{
    text-align: center;
}

.detaliiMiniTitle3 select{
    border: 1px solid #BDCCD5 !important;
    border-radius: 26px !important;
    font: normal normal normal 18px Alegreya;
    padding: 2px !important;
    min-width: 120px !important;
    margin-top: 10px;
}


.detaliiMiniTitle3red select{
    border: 1px solid #ff0000 !important;
    border-radius: 26px !important;
    font: normal normal normal 18px Alegreya;
    padding: 2px !important;
    min-width: 120px !important;
}

.detaliiMiniTitle5red select,
.detaliiMiniTitle2red select,
.detaliiMiniTitle2red select {
    border: 1px solid #ff0000 !important;
    border-radius: 26px !important;
    font: normal normal normal 18px Alegreya;
    padding: 2px !important;
    min-width: 152px !important;
}

.sectionLocuinteFransiza{
    text-align: center;
    background-color: #E0E7FF;
    padding-top: 20px;
    border-radius: 16px;
    padding-top: 30px;
    padding-left: 30px;
    padding-bottom: 20px;
    padding-right: 10px;
}

.detaliiMiniTitle2 input {
    width: 250px;
    height: auto;
    background-color: transparent;
    border: none;
    padding: 5px;
    border-bottom: 2px solid #BDCCD5;

    text-align: center;
}

.detaliiMiniTitle2red input {
    width: 250px;
    height: auto;
    background-color: transparent;
    border: none;
    padding: 5px;
    border-bottom: 2px solid #ff0000;

    text-align: center;
}

.detaliiMiniTitle4 input {
    width: 450px;
    height: auto;
    background-color: transparent;
    border: none;
    padding: 5px;
    border-bottom: 2px solid #BDCCD5;
    font: normal normal normal 18px Alegreya;
    text-align: center;
}

.detaliiMiniTitle4red input{
    width: 450px;
    height: auto;
    background-color: transparent;
    border: none;
    padding: 5px;
    border-bottom: 2px solid #ff0000;
    font: normal normal normal 18px Alegreya;
    text-align: center;
}
.detaliiMiniTitle3red input{
    width: 150px;
    height: auto;
    background-color: transparent;
    border: none;
    padding: 5px;
    border-bottom: 2px solid #ff0000;

    text-align: center;
}

.detaliiMiniTitle3 input{
    width: 120px;
    height: auto;
    background-color: transparent;
    border: none;
    padding: 5px;
    border-bottom: 2px solid #BDCCD5;

    text-align: center;
}


.detaliiButton2{
    font: 20px Alegreya;
    width: 80px;
    height: 51px;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    margin-left: 10px;
    margin-top: 10px;
    background-color: white;
    border: 1px solid white;

}

.detaliiButton2Red {
    font: 20px Alegreya;
    color: white;
    width: 80px;
    height: 51px;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    margin-left: 10px;
    margin-top: 10px;
    background-color: #6689FF;
    border: 1px solid #6689FF;

}

.sticker {
    width: 350px;
    border-radius: 26px 0px 0px 26px;
    position: fixed;
    top: 30;
    right: 0;
    background-color: #000;
    color: #fff;
    padding: 10px;
    font-size: 16px;
    z-index: 1;
    padding-left: 20px;
}

.locuinte_Button {
    font: 20px Alegreya;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    margin: 10px;
    margin-top: 10px;
    background-color: white;
    border: 1px solid #D9D4D9;
}

.locuinte_ButtonRed {
    font: 20px Alegreya;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    margin: 10px;
    margin-top: 10px;
    background-color: white;
    border: 1px solid #6689FF;

}

.locuinte_info {
    background: #FFFBCC 0% 0% no-repeat padding-box;
    border: 1px solid #FF9D00;
    border-radius: 16px;
    font: normal normal normal 10px/12px Alegreya;
    color: #C43500;
    text-align: left;
    
    margin: 10px;
        margin-top: 10px;

}

.ofertaTitleLocuinte{
    font: normal normal bold 16px/22px Alegreya;
        color: #FFFFFF;
}

.ofertaPretLocuinte{
    font: normal normal bold 28px/24px Alegreya;
    color: #FFD504;
}

.ofertaPretLocuinte2{
    font: normal normal bold 15px Alegreya;
    color: #FFD504;
}

.contactLocuinte  {
    text-align: right;

}
.LocuinteSpace{
    margin-bottom: 40px;
    /* width: 1400px; */
}

.locuinteTextLink{
    text-decoration: underline;
    font: normal normal bold 16px/49px Alegreya;
    color: #141414;
}

.errorTextLocuinte{
    font: normal normal normal 16px/26px Alegreya;
        color: #ff0000;
    text-align: left !important;
}

.displayLocuinteNone{
    display: none;
    color: transparent;
}

.sectionLocuinta_FransizaTitle{
    text-align: center !important;
    font: normal normal bold 22px/22px Alegreya;
    color: #000000;
}

.sectionLocuinta_FransizaEnd{
    margin-top: 20px;
    text-align: center;
    font: normal normal normal 14px/20px Alegreya;
}

.popup-containerTransparent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  text-align: center;
}

.locuinte_infopermanent{
    text-align: right;
    font: italic normal normal 12px/16px Alegreya;
    padding-top: 50px;
    color: #0B0B0B;
}
.whatsappLocuinte{
    font: normal normal bold 14px Alegreya;
    margin-top: -120px;
    margin-left: 70px;
}

.imgFooterNext{
    width: 430px;
    height: auto;
    margin-top: -200px;
}

.locuinteTextDownloade p{
    font: normal normal normal 18px/27px Alegreya; 
}

.buttonRefresh{
    width: 176px;
    height: auto;
}
.buttonPaid {
    width: 176px;
    height: auto;
}

.buttonRePlata {
    width: 200px;
    height: auto;
}

.locuinte_buttonText {
    font: normal normal bold 16px/22px Alegreya;
    margin-left: 56px;
    margin-top: -25px;
}

.locuinte_buttonText1 {
    font: normal normal bold 16px/22px Alegreya;
    margin-left: 40px;
    margin-top: -25px;
}

.locuinte_buttonPlataText1 {
    font: normal normal bold 20px/22px Alegreya;
    margin-left: 50px;
    margin-top: -30px;
}

.locuintaColFransiza{
    margin-left: 30px;
}

.locuinteImg {
    width: 200px;
    margin-left: 40px;
    height: auto;
}

.sectionlocuinta_Bunuri {
    background-color: #E0E7FF;
    padding-top: 20px;
    border-radius: 16px;
    padding-top: 30px;
    padding-left: 30px;
    padding-bottom: 20px;
    padding-right: 30px;
    text-align: center;
}

.sectionLocuinte_BunuriInfo {
    font: normal normal bold 25px/30px Alegreya;
    text-align: left;
    margin-bottom: 30px;
}

.sectionLocuinte_BunuriSubInfo {
    font: normal normal normal 18px/30px Alegreya;
}

.sectionLocuinte_BunuriText {
    font: normal normal bold 18px/30px Alegreya;
}

.sectionLocuinte_BunuriRow1 {
    text-align: center;
}

.sectionLocuinte_Bunuri1,
.sectionLocuinte_Bunuri {
    background-color: #E0E7FF;
    padding-top: 20px;
    border-radius: 16px;
    padding-top: 30px;
    padding-left: 30px;
    padding-bottom: 20px;
    padding-right: 30px;
}

.sectionLocuinte_Bunuri1 {
    margin-top: 20px;
}

.sectionLocuinte_BunuriAsig {
    font: normal normal bold 20px/24px Alegreya;
}

.sectionLocuinte_BunuriSubAsig {
    font: normal normal normal 12px/16px Alegreya;

}

.sectionLocuinte_BunuriAsigInput {
    width: 100px;
    height: auto;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 5px;
    border-bottom: 2px solid #000000;

    text-align: center;

}

.sectionLocuinte_BunuriAsigP {
    font: normal normal normal 10px/24px Alegreya;
}

@media screen  {

    .sticker {
        width: 200px;

    }

    .contactLocuinte img {
        width: 350px;
        height: auto;
        text-align: right;

    }
}



@media screen and (max-width: 500px) {

    .detaliiMiniTitle,

    .detaliiMiniTitle4,
    .detaliiMiniTitle11,

    .detaliiMiniTitle4 input {
        width: 250px !important;

    }


}

@media screen and (max-width: 750px) {

    .ofertaPretLocuinte {
        font: normal normal bold 20px Alegreya;
        color: #FFD504;
    }
    .buttonRefresh {
        width: 90px;
        height: auto;
    }

    .locuinte_buttonText {
        font: normal normal bold 16px/22px Alegreya;
        margin-left: 20px;
        margin-top: -20px;
    }
    .locuintaColFransiza {
        margin-left: 0px !important;
    }


}

@media screen and (max-width: 870px) {

    .locuinteImg{
        width: 170px;
        margin-left: 0px;
        height: auto;
    }
    .locuinteColImg{
        text-align: center;
    }
    .locuinteTextDownloade {
        margin-top: 20px;
    }
    .centerColum {
        text-align: left;
    }

}

@media screen and (max-width: 1000px) {

    .spaceBunuri {
        margin-bottom: 20px !important;
    }

    .imgFooterNext {
        width: 394px;
        height: auto;
        margin-top: 0px !important;
    }


}

@media screen and (max-width: 1250px ) {
    .spaceBunuri {
            margin-bottom: 40px;
        }

    .imgFooterNext {
        text-align: center;
        width: 394px;
        height: auto;
        margin-top: -200px;
    }

}

@media screen and (max-width: 1250px) {
    .detaliiMiniTitle4 input {
            width: 350px;

        }


}

@media screen and (min-width: 300px) {

    .sticker {
        width: 200px;

    }

    .contactLocuinte img {
        width: 250px;
        height: auto;
        text-align: right;

    }
}

@media screen and (max-width: 768px) {
    .sticker {
        width: 120px;
        top: 100px;
    }
}

@media screen and (min-width: 768px) {

.sticker {
        width: 200px;

    }

    .contactLocuinte img {
        width: 250px;
        height: auto;
        text-align: right;

    }
}

@media screen and (min-width: 1013px) {

    .sticker {
            width: 250px;
    
        }
    .contactLocuinte img{
        width: 400px;
        height: auto;
        text-align: right;

    }
}

@media screen and (min-width: 1304px) {

    .sticker {
            width: 350px;
    
        }
    .contactLocuinte {
        width: 400px;
        height: auto;
        text-align: right;

    }
}












.containerHP {
    width: 100%;
    min-height: 900px;
    height: auto;
    padding-top: 90px;

    background-color: #6689FF;
    font: Alegreya;
    overflow: hidden;
    padding-bottom: 100px;
}

.sectionHP_right {
    padding-left: 50px;
    margin-bottom: 100px;
}

.sectionHP_detalii {

    background-color: #E0E7FF;
    height: auto;
    text-align: center;


    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 26px;
    margin-top: 30px;


}

.HP_labelInputMni,
.HP_labelInput,
.HP_labelList {
    font: normal normal 600 22px/38px Alegreya;
    color: #000000;
    text-align: center;
}



.HP_miniTitle {
    text-align: left;
    font: normal normal 900 22px/49px Alegreya;
    margin-left: 50px;
}

.centered-select {
    margin-top: 10px;
    display: block;
    margin: 0 auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    font: normal normal normal 20px/54px Alegreya
        /* Aceasta linie asigură centrarea pe orizontală */
}

.HP_labelList select {
    width: 172px;
    height: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #BDCCD5;
    border-radius: 26px;
    /* padding-left: 20px;
    padding-right: 20px;*/
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: center;
}

.HP_labelInputMni input {
    width: 150px;
    height: auto;
    background-color: transparent;
    border: none;
    padding: 5px;
    border-bottom: 2px solid #BDCCD5;

    text-align: center;
}

.sectionHP_BunuriTextInput,
.HP_labelInput input {
    width: 300px;
    height: auto;
    background-color: transparent;
    border: none;
    padding: 5px;
    border-bottom: 2px solid #BDCCD5;

    text-align: center;

}

.sectionHP_BunuriTextInput {
    font: normal normal normal 18px/49px Alegreya;
    width: 200px;
    padding: 0px;
    margin-left: 10px;
}

.HP_labelInputMni input::placeholder,
.HP_labelInput input::placeholder {
    font: normal normal normal 18px/49px Alegreya;
    color: #BDCCD5;

}

.HP_Button {
    font: 20px Alegreya;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    margin: 10px;
    margin-top: 10px;
    background-color: white;
    border: 1px solid #D9D4D9;
}

.HP_ButtonRed {
    font: 20px Alegreya;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    margin: 10px;
    margin-top: 10px;
    background-color: white;
    border: 1px solid #6689FF;

}

.HP_info {
    background: #FFFBCC 0% 0% no-repeat padding-box;
    border: 1px solid #FF9D00;
    border-radius: 16px;
    font: normal normal normal 10px/12px Alegreya;
    color: #C43500;
    text-align: left;
    padding: 10px;
}

.HP_Button1 {
    font: 20px Alegreya;
    width: 60px;
    height: 51px;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    margin: 10px;
    margin-top: 10px;
    background-color: white;
}

.HP_Button2 {
    font: 20px Alegreya;
    width: 80px;
    height: 51px;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    margin: 10px;
    margin-top: 10px;
    background-color: white;
}

.HP_buttonText {
    font: normal normal bold 16px/22px Alegreya;
}

.OF1_title {
    font: normal normal bold 30px/49px Alegreya;
    text-align: left;
    margin-left: 36px;
}

.OF1_text {
    text-align: left;
    font: normal normal normal 16px/26px Alegreya;
    color: #0B0B0B;
    margin-left: 36px;

}

.OF1_info {
    text-align: center;
    font: normal normal bold 16px/26px Alegreya;
}

.inline-increments {
    margin-top: -60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inline-increments> :nth-of-type(2) {
    margin: 0 90px;
}

.OF-Button2 {
    margin-top: 229px;
}


.sectionHP_BunuriRow1 {
    text-align: center;
}

.sectionHP_Bunuri1,
.sectionHP_Bunuri {
    background-color: #E0E7FF;
    padding-top: 20px;
    border-radius: 16px;
    padding-top: 30px;
    padding-left: 30px;
    padding-bottom: 20px;
    padding-right: 30px;
}

.sectionHP_Bunuri1 {
    margin-top: 20px;
}

.sectionHP_BunuriDescriere {
    font: normal normal normal 12px/16px Alegreya;
}

.sectionHP_BunuriInfo {
    font: normal normal bold 30px/37px Alegreya;
    text-align: left;
    margin-bottom: 30px;
}

.sectionHP_BunuriSpace {
    padding-left: 5px;
    padding-right: 5px;

}

.sectionHP_BunuriSubInfo {
    font: normal normal normal 22px/37px Alegreya;
}

.sectionHP_BunuriText {
    font: normal normal bold 22px/38px Alegreya;
}

.sectionHP_BunuriAsig {
    font: normal normal bold 22px/24px Alegreya;
}

.sectionHP_BunuriSubAsig {
    font: normal normal normal 12px/16px Alegreya;

}

.custom-range {
    width: 180px;
    /* Ajustează lățimea la nevoie */
    height: 10px;
    /* Ajustează înălțimea la nevoie */
    background-color: #f50000;
    /* Culoarea fundalului */
    outline: none;
    /* Elimină conturul implicit */
}



.sectionHP_BunuriAsigInput {
    width: 100px;
    height: auto;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 5px;
    border-bottom: 2px solid #000000;

    text-align: center;

}

.sectionHP_BunuriAsigP {
    font: normal normal normal 10px/24px Alegreya;
}


@media screen and (max-width: 999px) {
    .inline-increments {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        /* Permite trecerea la afișarea pe mai multe linii */
    }
}

.sectionTravel_buy__form_back p {
    text-align: left !important;
    display: inline-block !important;
}

.sectionTravel_buy__form_back {
    margin-top: 30px;
    margin-left: 10px;
}

.sectionTravel_Travel__form_back p {

    text-align: left !important;
    display: inline-block !important;
}

.sectionTravel_Travel__form_back {
    margin-top: 10px;
    margin-left: 10px;
}

.sectionTravel_buy_button_back {
    font: normal normal bold 16px/22px Alegreya;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.sageata_travel {
    width: 25px;
    height: auto;
}