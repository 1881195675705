.titlePopUpInfo{
    font: normal normal bold 16px Alegreya;
    color: #C43500;
}

.textPopUpInfo{
    font: normal normal bold 13px Alegreya;
}

.popup-container {
    margin-top: 10px;
    width: 300px;
    position: fixed;
    background: #ffffff;
    padding: 8px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    border: 1px solid #6689ff;
    font: normal normal normal 16px Alegreya;
    height: auto;

    left: 50%;
    transform: translateX(-40%);

}

@media only screen and (max-width: 600px) {

    /* Stiluri specifice pentru ecrane mai mici */
    .popup-container {
        width: 200px;
        
            transform: translateX(-80%);
    }
}