.Footer_up_RCA{
  width: 100%;
  min-height: 250px;
  height: auto;
  padding-top: 0px;
  background: #EEF5FC;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
  font: Alegreya;
  overflow: hidden;
  margin: 0px;

}

.Footer_up_RCA_con{
  width: 100%;
}

.Footer_up_RCA_title{
  font: normal normal bold 32px/42px Alegreya;
  letter-spacing: 0px;
  color: #FBFBFB;
  padding-bottom: 40px;
  border-bottom: 2px solid white;
}

.Footer_up_RCA_title p{
  display: inline;
  padding-right: 40px;
}

.Footer_up_RCA_title button{
  display: inline;
}

.Footer_up_RCA_center_left{
  padding-top: 50px;
  text-align: left;
}

.Footer_up_RCA_center_right{
  padding-top: 50px;
  text-align: right;
}

.Footer_up_RCA_center_right p{
  font: normal normal normal 22px/26px Alegreya;
  letter-spacing: 0px;
  color: #000000;
}

.Footer_up_RCA_center_right img{
  padding-right: 10px;
}

.Footer_up_RCA_title a{
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .Footer_up_RCA_title p{
    font: normal normal normal 26px Alegreya;
    
  }

  .Footer_up_RCA_title button{
    margin-top: 30px;
  }

  .Footer_up_RCA_center_left{
    padding-top: 30px;
    text-align: center;
  }
  
  .Footer_up_RCA_center_right{
    padding-top: 30px;
    text-align: center;
    margin-bottom: 30px;
  }
}

.Footer_up_RCA_con {
  font: normal normal bold 32px/42px Alegreya;
  letter-spacing: 0px;
  border-top: 2px solid #BDCCD5;
}