.section_formRCA{
  width: 100%;
  min-height: 720px;
  height: auto;
  padding-top: 120px;
  
  background-color: #EEF5FC;
  text-align: center;
  
  margin: 0px;
  font: Alegreya;
  overflow: hidden;
  
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 20px;
  background: #fff;
  border: 1px solid #ccc;
}

.containerRCA__lp{
 
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BDCCD5;
  border-radius: 91px;
  opacity: 1;
  min-height: 517px;
  
}

.sectionRCA__left{
  text-align: left;
}
.sectionRCA__left p{
  
  letter-spacing: 0px;
  margin-left: 58px;
  text-align: left;
}

.sectionRCA__left_title1{
  font: normal normal bold 48px/49px Alegreya !important;
  margin-top: 103px;
  color: #000000;
}

.sectionRCA__left_title2_blue {
  font: normal normal bold 48px/49px Alegreya;
  margin-top: -20px;
  color: #66B6FF !important;
}

.sectionRCA__left_text1{
  margin-top: 47px;
    text-align: left;
    font: normal normal bold 18px/24px Alegreya;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.sectionRCA__left_text2{
  font: normal normal bold 18px/24px Alegreya;
  text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.sectionRCA__lp img{
  float: center;
  
}

.rca_right1,
.rca_right2,
.rca_right3 {
  margin-bottom: 100px;
}

.sectionRCA__right_title1{
  font: normal normal bold 36px/57px Amatic SC;
  text-align: right;
  margin-right: 71px;
  margin-top: 57px;
  color: #66B6FF;
}



.rca_btn{
  margin-top: 20px;
  margin-left: 60px;
  width: 243px;
  height: auto;
}

.rca_btn:hover {
  cursor: pointer;
}

.sectionRCA__left_title3{
  font: normal normal bold 48px/49px Alegreya;
  margin-top: -20px;
    color: #000000;
}

.sectionRCA__left_text1 {

  text-align: left;
  font: normal normal bold 16px/22px Alegreya;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.sectionRCA__left_text2 {
  font: normal normal bold 16px/22px Alegreya;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.sectionRCA__left_text4{
  margin-top: 84px;
    font: normal normal bold 18px/24px Alegreya;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.carousel-indicators{
 
  left: 0 !important;
  right: 0 !important;
  margin-left: 20px !important;
  justify-content: right !important;
  margin-right: 350px !important;
  bottom: 40px!important;
}

.section_formRCA .slick-dots button {
  width: 18px !important;
    height: 18px !important;
    background: #BDCCD5 0% 0% no-repeat padding-box !important;
      border: 1px solid #BDCCD5 !important;
    opacity: 1;
    border-radius: 10px;
}


.section_formRCA .slick-dots {
  position: absolute;
  bottom: 60px;
  list-style: none;

  /* text-align: center; */
  text-align: right;
  right: 22%;
  width: 100%;
}

.section_formRCA .slick-dots li {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}

.section_formRCA .slick-dots li button {

  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #BDCCD5 !important;
  background-color: #FFFFFF !important;
  border: none;
  padding: 0;
  text-indent: -9999px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.5s ease;
}

.section_formRCA .slick-dots li.slick-active button {
  opacity: 1;
  background: #BDCCD5 0% 0% no-repeat padding-box !important;
    border: 1px solid #BDCCD5 !important;

  
}

/* @media screen and (max-width: 1200px){

  .rca_right{
    margin-bottom: 30px;
  }

} */

@media screen and (max-width: 1200px) {

  .section_formRCA .slick-dots {
    bottom: 80px;
  }


}

@media screen and (max-width: 500px) {

  .rca_right1 {
    width: 300px;
    height: auto;
  }

  .rca_right2 {
    width: 300px;
    height: auto;
  }

  .rca_right3 {
    width: 200px;
    height: auto;
  }

  .containerRCA__lp {
    min-height: 950px;

  }
   .sectionRCA__left p {

    margin-left: 8px;

  }


}