.section8{
  width: 100%;
  min-height: 950px;
  height: auto;
  padding-top: 129px;
  background: #FFD506;
  text-align: center;
  font: Alegreya;
  overflow: hidden;

}

.section8_container{
  height: 511px;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  background: #FFFFFF;
}

.section8_Title{
  padding-top: 42px;
  font: normal normal bold 24px/58px Alegreya;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 20px;
}
.section8_foodter_center{
  padding-top: 97px;
  font: normal normal bold 37px/26px Amatic SC;
  letter-spacing: 0px;
  color: #1E1E1E;
}

.section8_button{
  padding-top: 50px;
}

.button_asigurare_negru{
  background: black;
  text-align: center;
  font: normal normal bold 16px/22px Alegreya;
  letter-spacing: 0px;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 45px;
  padding-right: 45px;
  border-radius: 40px;
  border-color: black;
}

.section8_foodter_left{
  padding-top: 50px;
  text-align: right;
}

.section8_foodter_right{
  padding-top: 50px;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .section8{
    padding-top: 80px;
  
  }
  
  .section8_Title{
    padding-top: 22px;
    margin-bottom: 25px;
    font: normal normal bold 26px Alegreya;
  }

  .section8_foodter_left{
    display: none;
  }
  
  .section8_foodter_right{
    display: none;
  }

  .section8_foodter_center{
    font: normal normal bold 30px Amatic SC;
  }

  .section8_button{
    padding-bottom: 20px;
  }
  
}

@media screen and (max-width: 1000px) {
  .section8_foodter_center{
    font: normal normal bold 30px Amatic SC;
  }
}

@media screen and (max-width: 450px) {
  .section8_container{
    height: 651px;
  }
}
