.section6{
  width: 100%;
  min-height: 1188px;
  height: auto;
  padding-top: 90px;
  text-align: center;
  background-color: #66B6FF;
  font: Alegreya;
  overflow: hidden;
  
}

.section6__form__benefits{
  text-align: center ;

}

.section6__form__benefits h3{
  margin-top: 36px;
  margin-bottom: 17px;
  font: normal normal bold 24px/32px Alegreya;
}


.section6__form__benefits h4 {
  margin-top: 17px;
  margin-bottom: 17px;
  font: normal normal bold 24px/32px Alegreya;
}

.section6__form__benefits h5 {
  margin-top: 9px;
  margin-bottom: 17px;
  font: normal normal bold 24px/32px Alegreya;
}

.section6__form__benefits p{
  ffont: normal normal bold 18px/22px Alegreya;
}

.section6__Title{
  font: normal normal bold 48px/58px Alegreya;
  letter-spacing: 0px;
  color: #F3F3F3;
}

.section6__content{
  display: inline;
  padding-bottom: 20px;
}

.section6__subTitle{
  font: normal normal bold 18px/24px Alegreya;
  letter-spacing: 0px;
  color: #060606;
}

.section6__form{
  padding-top: 142px;
}

.section6__form__content{
  text-align: left;
  letter-spacing: 0px;
  color: #242424;
}

.section6__form__content_Title{
  font: normal normal bold 30px/33px Alegreya;
}

.section6__form__content_subTitle{
  font: normal normal bold 24px/22px Alegreya;
  padding-top: 45px;
}

.section6__form__content_subTitle2{
  margin-top: -15px;
  font: italic normal normal 14px/22px Alegreya;
  
}

.section6_CheckBox{
  text-align: left;
  font: normal normal bold 20px/25px Amatic SC;
  letter-spacing: 0px;
}

.section6__form_label1{
  font: normal normal bold 20px/25px Amatic SC;
  display: inline;
  letter-spacing: 0px;
  color: #242424;
  text-align: center;
  border: 1px solid #242424;
  padding: 10px;
  border-radius: 20px;
  margin-left: 5px;
}
.section6__form_label1:hover {
  cursor: pointer;
}

.section6__form_label2{
  font: normal normal bold 20px/25px Amatic SC;
  display: inline;
  letter-spacing: 0px;
  color: #ffffff;
  background-color: black;
  text-align: center;
  border: 1px solid #000000;
  padding: 10px;
  border-radius: 20px;
  margin-left: 5px;
}
.section6__form_label2:hover {
  cursor: pointer;
}

.section6__form_label1 label{
  padding: 20px;
}
.section6__form_label1 label:hover {
  cursor: pointer;
}

.section6__form_label2 label{
  padding: 20px;
}

.section6__form_label2 label:hover {
  cursor: pointer;
}

.section6__form_input1{
  display: none;
}

.section6__form_input2{
  display: inline;
  margin-left: 20px;
}

.section6__form_input2 input{ 
  
  width: 50%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #3E3E3E;
  background: transparent;

  font: italic normal normal 14px/19px Alegreya;
  letter-spacing: 0px;
  color: #3E3E3E;

}

.section6_button{
  padding-top: 35px;
  padding-bottom: 40px;
}

.button{
  background: #FFD506;
  text-align: center;
  font: normal normal bold 16px/22px Alegreya;
  letter-spacing: 0px;
  color: #000000;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 44px;
  padding-right: 44px;
  border-radius: 50px;
  border-color: #FFD506; 

}

@media screen and (max-width: 768px) {

  .section6__Title{
    font: normal normal bold 39px Alegreya;
  }
  
  .section6__subTitle{
    font: normal normal bold 14px Alegreya;
  }

  .section6__form__content_Title{
    font: normal normal bold 24px Alegreya;
  }

  .section6__form__content_subTitle{
    font: normal normal bold 14px Alegreya;
  }

  .section6__form__content_subTitle2{
    font: 14px Alegreya;
  }
  .section6__content img{
      max-width: 50%;
  }

}

@media screen and (max-width: 508px){
  .section6__content img{
    max-width: 60%;
}
}

@media screen and (max-width: 368px){
  .section6__form_label1 {
    font: normal normal bold 14px Amatic SC;
  }
  .section6__form_label2 {
    font: normal normal bold 14px Amatic SC;
  }
}

