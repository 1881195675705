.section_confirmareMesaj{
  width: 100%;
  min-height: 820px;
  height: auto;
  padding-top: 120px;
  
  background-color: #66B6FF;
  text-align: center;
  
  margin: 0px;
  font: Alegreya;
  overflow: hidden;
  
}


.containerConfirmareMesaj__form{
 
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BDCCD5;
  border-radius: 91px;
  opacity: 1;
  min-height: 517px;
  
}

.confirmareMesaj_title img{
  margin-top: 49px;
  margin-bottom: 43px;

}

.confirmareMesaj_title h3{
  margin-bottom: 20px;
  font: normal normal bold 30px/27px Arial;
}

.confirmareMesaj_title p {
  font: normal normal normal 16px/27px Arial;
}

.confirmareMesaj_subtitle{
  margin-top: 50px;
font: normal normal normal 16px/14px Arial;
}

.onfirmareMesaj_SM a{
  margin: 10px;
}

.onfirmareMesaj_SM {
  margin-bottom: 50px;
}

.confirmareMesaj_TC{
  margin-top: 20px !important;
}

.confirmareMesaj_TC1 a{
  
  text-align: left;
  font: normal normal bold 13px/19px Arial;
    letter-spacing: 0.33px;
}

.confirmareMesaj_TC2 a {

  text-align: right;
  font: normal normal bold 13px/19px Arial;
  letter-spacing: 0.33px;
}

.confirmareMesaj_info {
  margin-top: 30px !important;
}

.confirmareMesaj_info p{
  text-align: center;
    font: normal normal normal 8px/10px Arial;
    letter-spacing: 0.24px;
    color: #506189;
}