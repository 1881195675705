.buttonDownloadeLocuinte{
    background-color: rgba(240, 248, 255, 0);
    border: 0;
    text-decoration: underline;
    letter-spacing: 0px;
    color: #000000;
    font: normal normal normal 12px/16px Alegreya;
}

.buttonDownloadeLocuinteText {
    text-align: left;
    font: italic normal normal 12px/16px Alegreya;
    padding-top: 50px;
    color: #0B0B0B;
}

