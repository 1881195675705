.sectionMedical{
  width: 100%;
  min-height: 530px;
  height: auto;
  background-color: #FBFBFB;
 
  color: #000000;
  text-align: left;

  overflow: hidden;
  
}

.Contact:hover {
  cursor: pointer;
}



.sectionBusiness_title1 {
  font: normal normal bold 30px/57px Amatic SC !important;
  letter-spacing: 0px;
  color: #FE9D35;
  opacity: 1;
}

.IlustratieMalpraxis{
  margin-bottom: 99px;
}

.sectionMedical__subtitle2{
  text-align: left;
    font: normal normal bold 48px/58px Alegreya;
    letter-spacing: 0px;
  margin-top: -25px;
    
}

.sectionMedical__subtitle3 {
  margin-top: 49px;
  font: normal normal bold 16px/22px Alegreya;
    letter-spacing: 0px;
  margin-bottom: 10px;

}


@media screen and (max-width: 1100px) {

  .IlustratieBusiness {
    text-align: center !important;
    margin-bottom: 20px;
    width: 340px;
      height: auto;

    }
    .sectionBusiness_title1 {
      font: normal normal bold 28px/57px Amatic SC !important;

    }
    .sectionMedical__subtitle2{
      font: normal normal bold 39px Alegreya;
    }
    .sectionMedical__subtitle3{
      font: normal normal bold 14px Alegreya;
    }

    .Contact{
      scale: 0.8;
    }

}

@media screen and (max-width: 360px) {

  .IlustratieBusiness {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 250px;
    height: auto;

  }

}
