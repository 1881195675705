.section5{
  width: 100%;
  min-height: 821px;
  height: auto;
  background-color: #F3F3F3;

  text-align: center;
  margin: 0px;
  padding: 0px;
  font: Alegreya;
  overflow: hidden;
} 

.section5__Title{
  padding-top: 75px;
  font: normal normal bold 48px/58px Alegreya;
  letter-spacing: 0px;
  color: #000000;

}

.section5__subTitle{
  font: normal normal bold 18px/24px Alegreya;
  letter-spacing: 0px;
  color: #000000;

}



.section5__textBeneficii{
  text-align: left;
  margin-left: -30px;
}

.section5__textBeneficii_Title{
  font: normal normal bold 18px Alegreya;
  letter-spacing: 0px;
  color: #3D3D3D;
  

}

.section5__textBeneficii_subTitle{
  margin-top: -15px;
  font: normal normal bold 16px Alegreya;
  letter-spacing: 0px;
  color: #3D3D3D;

}

.section5__beneficii_1{
  padding-top: 80px;
}

.section5__beneficii{
  padding-top: 45px;
}

.section5__Btn{
  padding-top: 30px;
}

@media screen and (max-width: 767px) {

  .section5__textBeneficii{
    text-align: center;
    margin-left: 0px;
  }

  .section5__textBeneficii_Title{
    padding-top: 20px;
  }


  .section5__Btn{
    padding-bottom: 30px;
  }
  
  .section5__Title{
    font: normal normal bold 39px Alegreya;
  }

  .section5__subTitle{
    font: normal normal bold 14px Alegreya;
  }


}

