.section7{
  width: 100%;
  min-height: 1250px;
  height: auto;
  padding-top: 73px;
  text-align: left;
  background: #FBFBFB;

  text-align: center; 

  font: Alegreya;
  overflow: hidden;
}

.section7_Title{

  font: normal normal bold 48px/58px Alegreya;
  letter-spacing: 0px;
  color: #000000;
}

.section7_subTitle{
  padding-top: 60px;
  font: normal normal bold 22px/26px Alegreya;
  letter-spacing: 0px;
  color: #151515;
}

.section7_pasi{
  padding-top: 32px;
  padding-right: 10px;
}

.Pasii_de_facut{
  width: 95%;
}

.section7_pasi_tele-Portare{
  font: normal normal bold 16px/22px Alegreya;
  letter-spacing: 0px;
  color: #1E1E1E;
  text-align: left;
}

.section7_button{
  padding-top: 90px;
  text-align: left;
}

.section7_Title2{
  padding-top: 120px;
  font: normal normal bold 48px/58px Alegreya;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
}

.section7_subTitle2{
  text-align: center;
  font: normal normal bold 18px/24px Alegreya;
  letter-spacing: 0px;
  color: #060606;
}

.section7_share_title{
  padding-top: 35px;
  text-align: center;
  font: normal normal bold 24px/30px Amatic SC;
  letter-spacing: 0px;
  color: #000000;
}

.section7_share_subTitle{
  text-align: center;
  font: normal normal bold 16px/22px Alegreya;
  letter-spacing: 0px;
  color: #66B6FF;
}

.section7_share_icon{
  text-align: center;
  padding-top: 22px;
  padding-bottom: 22px;
}
.section7_share_icon img{
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  .section7_Title{
    font: normal normal bold 39px Alegreya;
  }
  .section7_subTitle{
    font: normal normal bold 18px Alegreya;
  }

  .section7_pasi_tele-Portare{
    margin-top: 20px;
    font: normal normal bold 14px Alegreya;
    text-align: left;
    margin-left: 10px;
  }
  .section7_button{
    padding-top: 20px;
  }
  .section7_Title2{
    font: normal normal bold 39px Alegreya;
  }

  .section7_subTitle2{
    font: normal normal bold 14px Alegreya;
  }

  .Crestem_comunitatea{
    margin-bottom: 20px;
  }
}

