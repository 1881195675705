.section_formTravel{
  width: 100%;
  min-height: 720px;
  height: auto;
  padding-top: 120px;
  
  background-color: #EFECEF;
  text-align: center;
  
  margin: 0px;
  font: Alegreya;
  overflow: hidden;
  
}
.confirmare_travel_title{
  margin-top: 42px;
  text-align: center;
    font: normal normal bold 34px/44px Alegreya;
    letter-spacing: 0px;
    color: #000000;
}
.containerTravel__form{
 
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #EFECEF;
  border-radius: 91px;
  opacity: 1;
  min-height: 517px;
  
}

.sectionTravel__formConfirmare{
  margin-top: 50px !important;
  margin-bottom: 50px;
}

.sectionTravel__formConfirmare_subtitle{
  text-align: center;
    font: normal normal bold 20px/28px Alegreya;
    letter-spacing: 0px;
    color: #000000;
}

.sectionTravel__formConfirmare_text{
  margin-top: 40px;
  text-align: center;
    font: normal normal normal 16px/24px Alegreya;
    letter-spacing: 0px;
    color: #000000;
}

.travel_btn_confirmare{
  
  width: 300px;
    height: auto;
}

.travel_btn_confirmare:hover {
  cursor: pointer;
}

.check_btn{
  margin-left: 5px;
}

.sectionRCA__right{
  text-align: center;
}

.conTravel_sticky{
  position: fixed;
    top: 25%;
    right: 5%;
}

.conTravel_sticky:hover {
  cursor: pointer;
}

.sectionTravel__Loading{
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  font: normal normal bold 34px/44px Alegreya;
}