.section9{
  width: 100%;
  min-height: 640px;
  height: auto;
  padding-top: 136px;
  background: #FBFBFB;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  padding-bottom: 150px;
  font: Alegreya;
  overflow: hidden;

}

.section9_title{
  font: normal normal bold 32px/42px Alegreya;
  padding-top: 70px;
  }

.section9_subTitle{
  font: normal normal bold 16px/26px Alegreya;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 60px;

}

.section9_newsletter{
  display: inline;
   
}

.section9_newsletter input{
  width: 40%;
  padding: 10px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #3E3E3E;
  border-radius: 20px;
  font: italic normal normal 14px/19px Alegreya;
  letter-spacing: 0px;
  color: #3E3E3E;
  margin-right: 10px;

}

.newsletter{
  width: 95%;
}

@media screen and (max-width: 991px){
  .section9_title{
    font: normal normal bold 26px Alegreya;
  }
  .section9{
    padding-top: 86px;
  }
  .newsletter{

    width: 80%;
    text-align: center;
    align-items: center;
  }

  .section9_subTitle{
    padding-bottom: 30px;
    font: 16px Alegreya;
  }

  .section9_newsletter input{
    width: 60%;
    padding: 10px;
  }

  .section9_newsletter button{
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 500px) {
  .section9_newsletter input{
    width: 50%;
    padding: 10px;
  }

  .section9_newsletter button{
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 500px) {
  .section9_newsletter input{
    width: 90%;
    padding: 10px;
  }

  .section9_newsletter button{
    margin-top: 10px;
  }
}