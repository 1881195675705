.formContact{
  width: 100%;
  min-height: 800px !important;
  height: auto;
  padding-top: 190px;
  
  background-color: #66B6FF;
  text-align: center;
  color: #000000;
  margin: 0px;
  font: Alegreya;
  overflow: hidden;
}

.formContact_titlu p{
  text-align: left;
    font: normal normal bold 20px/26px Alegreya;
}

.formContact_left{
  text-align: left;
}

.formContact_input{
  font: normal normal normal 16px/26px Alegreya;
    color: #161616;
}

.formContact_input input{
  background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #3E3E3E;
    border-radius: 10px;
    width: 280px;
      height: 35px;
}

.formContact_left_campuri{

  margin-top: 24px;
}


.formContact_input select{
  width: 278px;
    height: 35px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #3E3E3E;
    border-radius: 10px;
}

.formContact_inputMesage{
  margin-top: 20px;
  font: normal normal normal 16px/26px Alegreya;
    color: #161616;
  display: grid;
}

.formContact_inputMesageDiv{
  display: grid;
}

.formContact_inputMesage textarea{
  width: 575px;
    height: 106px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #3E3E3E;
    border-radius: 10px;

}

.formContact_titlu{
  text-align: left;
    font: normal normal bold 20px/26px Alegreya;
    letter-spacing: 0px;
    
}

.formContact_inputChech{
  margin: 0px;
  font: normal normal normal 14px/26px Alegreya !important;

}

.TrimiteRaspunsul{
  
  margin-top: 15px;
  width: 158px;
    height: auto;
}

.TrimiteRaspunsul:hover {
  cursor: pointer;
}

.formContact_inputTC label {
  display: inline-block !important;
  margin-right: 10px;
}

.formContact_inputTC label input
 {
  display: inline-block !important;
  margin-right: 5px;
}



@media screen and (max-width: 1399px) {

  .formContact_left_campuri1 {
    margin-left: 40px;
  }
}

@media screen and (max-width: 1200px) {

  .formContact_left_campuri1 {
    margin-left: 0px;
  }
}
@media screen and (max-width: 660px) {
.formContact_inputMesage textarea {
  width: 280px;
  height: 106px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #3E3E3E;
  border-radius: 10px;

}
}




