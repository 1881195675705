.chat-bubble {
    position: relative;
    display: inline-block;
    border-radius: 10px;
    padding: 10px;
    
    margin: 30px;
    max-width: 90%; 
    font-size: 14px;
    word-wrap: break-word;
    padding-bottom: 30px;
    padding-right: 20px;
}

.CB-title {
    margin: 0;
    padding-left: 30px;
    font: normal normal bold 48px/49px Alegreya;
    color: #000000;
}

.CB-title2 {
    margin: 0;
    padding-left: 35px;
    font: normal normal bold 40px/49px Alegreya;
    color: #000000;
}

.chat-bubble.left {
    background-color: #E0E7FF;
    float: left;
}

.chat-bubble.right {
    background-color: #E0E7FF;
    float: right;
}

.chat-bubble.left::before {
    content: "";
    position: absolute;
    top: 40%;
    left: -20px;
    border-style: solid;
    border-width: 25px 0 25px 45px;
    border-color: transparent transparent transparent #E0E7FF;
    transform: rotate(180deg);
}

.chat-bubble.right::after {
    content: "";
    position: absolute;
    right: -10px;
    top: 15px;
    width: 0; 
    height: 0;
    border: 15px solid transparent;
    border-right-color: #D9D4D9;
    border-right: 0;
    border-bottom: 0;
}



@media screen and (max-width: 1213px) {
    .CB-title {
        margin: 0;
        padding-left: 30px;
        font: normal normal bold 36px/49px Alegreya;
        color: #000000;
    }
    .CB-title2 {
        margin: 0;
        padding-left: 10px;
        font: normal normal bold 30px/49px Alegreya;
        color: #000000;
    }
    
}

@media screen and (min-width: 1214px) {
    .CB-title {
        margin: 0;
        padding-left: 30px;
        font: normal normal bold 40px/49px Alegreya;
        color: #000000;
    }
}

@media screen and (max-width: 400px) {
    .CB-title {
        font: normal normal bold 30px Alegreya;

    }
    .CB-title2 {
        margin: 0;
        padding-left: 10px;
        font: normal normal bold 30px/49px Alegreya;
        color: #000000;
    }
}