.FooterBlack{
  width: 100%;
  min-height: 220px !important;
  height: auto;
  padding-top: 35px;
  background: black;
  color: #FBFBFB;
  font: Alegreya;
  overflow: hidden;
  margin: 0px;

}


.FooterBlack_center_left{
  margin: 0px !important;
  color: floralwhite;
  text-align: left;
  display: flex;
  justify-content: start;
}

.FooterBlack_contact{
  margin-left: 40px;
  
}


.FooterBlack_contact a img{
  margin-right: 10px !important;
}

.FooterBlack_contact p {
  font: normal normal bold 16px/26px Alegreya;
}

.FooterBlack_contact a {
  font: normal normal normal 16px/26px Alegreya;
color: #EBEBEB !important;
display: block;
}



.FooterBlack_center_right{

  text-align: right;
}

.FooterBlack_center_right p{
  font: normal normal normal 22px/26px Alegreya;
  letter-spacing: 0px;
  color: #EBEBEB;
}

.FooterBlack_center_right img{
  padding-right: 10px;
}

.FooterBlack_title a{
  text-decoration: none;
}

.FooterBlack_center_RowLink{
  padding-top: 0px;
  margin-bottom: 40px;
}


.equal-width {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.FooterBlack_center_SubTitle{
  padding-top: 50px;
  font: normal normal bold 20px/26px Alegreya;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.FooterBlack_center_LinkDiv{

  background: #66B6FF 0% 0% no-repeat padding-box;
  border-radius: 9px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 151px;
  margin-top: 10px;

}

.FooterBlack_center_LinkDiv a{
  text-decoration: underline;
  font: normal normal bold 13px/19px Alegreya;
  letter-spacing: 0.39px;
  color: #FFFFFF;
  
}

.FooterBlack_center_LinkDiv a:hover {
  text-decoration: underline;
  font: normal normal bold 13px/19px Alegreya;
  letter-spacing: 0.39px;
  color: #FFFFFF;
}

.FooterBlack_center_Link{
  margin-top: 10px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .FooterBlack_title p{
    font: normal normal normal 26px Alegreya;
    
  }

  .FooterBlack_title button{
    margin-top: 30px;
  }

  .FooterBlack_center_left{
    padding-top: 30px;
    padding-top: 30px;
    
      margin-bottom: 30px;
    
    
      justify-content: center;
  }
  
  .FooterBlack_center_right{
    padding-top: 30px;
    text-align: center;
    margin-bottom: 30px;
  }




}