.heroConditii {
  width: 100%;
  min-height: 316px;
  height: auto;
  background-color: #66B6FF;
  
  margin: 0px;
  padding: 0px;
  font: Alegreya;
  overflow: hidden;


 

}

.heroConditiiColText{
display: flex;
  align-items: flex-end;
}

.heroConditiiText {
  text-align: left;
    font: normal normal bold 48px/66px Alegreya;

  color: #FFFFFF;
  align-self: flex-end;
  margin-bottom: 30px;


}


@media screen and (max-width: 1000px) {


  .heroConditiiText{
margin-bottom: 0px;
    margin-top: 120px;
    font: normal normal bold 48px/66px Alegreya;
  }

  


}

@media screen and (max-width: 380px) {

  .heroConditiiText {

      font: normal normal bold 38px/66px Alegreya;
      margin-bottom: 20px;
    }

}