.popup-containerTab {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid black;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
        flex-direction: column;

}

.popup-exist {
    position: absolute;
    top: 10px;
    left: 10px;
    background: #66B6FF;
    border-radius: 10px;
}

.popup-text-left1 {
    margin-top: 30px;
    text-align: left;

}

.popup-text-left {
    text-align: left;
    margin-top: 5px;
}


.popup-text {
    text-align: left;

}

.popup-text {
    font: normal normal normal 16px/26px Alegreya;
}


.popup-text input[type="checkbox"] {
    margin-right: 10px;
    display: inline-block;
}
.popup-button{
    margin-top: 30px;
}

.valoare_input {
    border: 1px solid #3E3E3E;
    border-radius: 10px;
}

