
.header{
    position: fixed;
    width: 100%;
    height: 80px;
    height: auto;
    line-height: 70px;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: #66B6FF;
    z-index: 999;
}

.logo img{
  text-align: left;
  width: 100%;
}

.menu{
  text-align: right;
  align-items: flex-end;
  text-align: right;
}

.nav__list {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: inline-flex;
    column-gap: 1.3rem;
    
  }

.nav__item a {
  align-items: right;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    text-decoration: none;
    transition: 0.3s;   
}

.menu a:hover{
    color: #ffffff;
}

.menu img{
  margin-left: 10px;
}

.nav__right p {
    font-weight: 500;
    color: #0a2b1e;
  }


.mobile__menu i {
   display: inline-block;
    font-size: 25px;
    color: white;
    cursor: pointer;
}

.mobile__menu img{
  margin-top: -15px;
  margin-left: 10px;
}

.mobile__menu {
    margin-top: 10px;
    display: inline;
    display: none;
}

.meniu_mobile_open {

  display: none;
}
.meniu_mobile_close{
  display: none;
}

.whats{
  padding-left: -160px;
  border-radius: 25px;
  
  background: #25D366;
  cursor: pointer;

}

.menu3{
  margin-left: 5px;
  margin-right: -40px;
  background: #25D366;
  text-decoration: none;
  color: white;

  padding: 10px; 
  padding-right: 50px;
  width: 200px;
  height: 150px; 
  border-radius: 25px;
}

.nav__menu{
  display: contents;
}




@media only screen and (max-width: 991px) {

  .Whatsapp_btn {
    display: block;
      width: 100%;
      height: auto;
      transform: scale(0.6);
  }


  .mobile__menu {
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
    display: inline;
    display: block;
  }

  .nav__menu {
    display: none !important;
  }


  .meniu_mobile_open {
    text-align: center;
    display: block;
    margin-top: -30px;
  }
  .meniu_mobile_open a{
    display: block;
    color: #000000;
  }
  .meniu_mobile_open a:link{
    text-decoration: none;
  }

  .meniu_mobile_open2{
    margin-top: -40px;
    margin-bottom: 0px;
    
  }

  .meniu_mobile_close {
    display: none;
  }

  .active__menu {
    display: block;
  }


  .logo img{
    text-align: left;
    width: 100%;
  }

  .whats{
    margin-top: -20px;
    margin-left: 10px;
  
  }

}

.custom-dropdown {
  position: relative;
  display: inline-block;
}

.selected-option {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.selected-option img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.options {
  position: absolute;
  display: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.options li {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 8px;
}

.options li:hover {
  background-color: #f0f0f0;
}

.options li img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

