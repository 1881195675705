.section4{
  width: 100%;
  min-height: 380px;
  height: auto;
  background-color: #FBFBFB;
  padding-top: 54px;

  text-align: center;
  font: Alegreya;
  overflow: hidden;
}

.section4__title{

  text-align: left;
  font: normal normal bold 32px/58px Alegreya;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  
}

.section4__subtitle1{
  width: 80%;
  margin-top: 36px;
  text-align: left;
  font: normal normal bold 16px/22px Alegreya;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.section4__subtitle2{
  width: 80%;
  text-align: left;
  font: normal normal bold 16px/22px Alegreya;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.section4__asiguratori img{
  padding: 10px;
}




.section4__asiguratori{
  margin-top: -10px;
}

.section4__slider {
  margin-top: 20px !important;
}

@media screen and (max-width: 1133px) {

.section4__asiguratori{
  scale: 0.8;
}
}
@media screen and (max-width: 990px) {


  .section4__title{
    font: normal normal bold 26px Alegreya;
  }
 
  .section4{
    width: 100%;}

  



  .section4__subtitle1{
    font: normal normal bold 14px Alegreya;
    width: 100%;
  }
  .section4__subtitle2{
    font: normal normal bold 14px Alegreya;
    width: 100%;
  }

}