.section_error{
  width: 100%;
  min-height: 920px;
  height: auto;
  padding-top: 120px;
  
  background-color: #EEF5FC;
  text-align: center;
  
  margin: 0px;
  font: Alegreya;
  overflow: hidden;
  
}
.error_rca_title{
  margin-top: 61px;
  text-align: center;
    font: normal normal bold 16px/20px Arial;
    letter-spacing: 0px;
    color: #000000;
}

.error_rca_subtitle{
  margin-top: 41px;
    text-align: center;
    font: normal normal normal 16px/20px Arial;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 30px;
}

.containerRCA__form{
 
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BDCCD5;
  border-radius: 91px;
  opacity: 1;
  min-height: 517px;
  
}

.sectionRCA__error{
  margin-top: 9px;
}
.sectionRCA__error a{
  margin-top: 90px;
  font: normal normal normal 16px/15px Arial;
}