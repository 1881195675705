.senfluaContact{
  width: 100%;
  min-height: 850px;
  height: auto;
  padding-top: 90px;

  background-color: #66B6FF;
  justify-content: end;

  overflow: hidden;
}



.senfluaContact_left {
  padding-top: 25px;
    background: #FFFFFF;
    border: 1px solid #BDCCD5;
    border-radius: 91px;
    min-height: 450px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.senfluaContact_top {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
}

/* .senfluaContact_center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;

} */

.senfluaContact_bottom {
  margin-bottom: -30px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: auto;
}

.senfluaContact_right{
  display: flex;

}

.imgSenfluaContact{
  align-self: flex-end;
  justify-content: center;
  width: 200px;
  height: 164px;
  
}

.senfluaContact_BackImg{
  margin-right: 5px;
}


.senfluaContact_Back{
  text-align: center;
  align-self: flex-start;
  margin-top: 0;
  color: #000000;
  text-decoration: none;
}

.senfluaContact_Back:hover{
  color: #000000;
}

.nameSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.nameTitle{
  font: normal normal normal 30px/40px Alegreya;
    letter-spacing: 0px;
    color: #000000;
}

.nameTitle2 {
  font: normal normal bold 30px/40px Alegreya;
  letter-spacing: 0px;
  color: #000000;
}

.namesubTitle{
  font: normal normal normal 16px Alegreya;
  letter-spacing: 0px;
  color: #000000;

}

.snfluaNameInput{
  border: none;
  border-bottom: 1px solid #BDCCD5;
  margin-bottom: 30px;
  width: 300px;
  outline: none;
}

.snfluaNameInput::placeholder {
  text-align: center;
  font: normal normal normal 18px/49px Alegreya;
  margin-bottom: 10px;
}

.senfluaFormCkeck{
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 10px;
}

.senfluaFormCkeck input {
  margin-right: 10px;
}

.senfluaFormCkeck label {
  text-align: left;


}

.senfluaFormTipAsigImg{
  width: 102px;
    height: auto;
  display: block;
}

.numeTileTip{
  font: normal normal bold 26px Amatic SC;
  color: #FBBCFB;
}

.senfluaFormTipAsigTip {
  font: 20px Alegreya;
  width: 213px;
  height: 179px;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* adăugat pentru a afișa imaginea și textul pe o linie nouă */
  border: 1px solid #BDCCD5;
  border-radius: 26px;
  /* padding-bottom: 34px; */
  margin: 10px;
}

.senfluaFormTipAsigImgTip{
  width: 45px;
    height: auto;
}
.senfluaFormTipAsigLifeDiv{
  display: inline-flex;
    justify-content: center;
    align-items: center;
}
.senfluaFormTipAsigLife{

  font: 20px Alegreya;
  width: 110px;
  height: 104px;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* adăugat pentru a afișa imaginea și textul pe o linie nouă */
  border: 1px solid #BDCCD5;
  border-radius: 26px;
  /* padding-bottom: 34px; */
  margin: 10px;
  margin-top: 35px;
  
}

.senfluaFormTipAsigLife_active{
  font: 20px Alegreya;
    width: 110px;
    height: 104px;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* adăugat pentru a afișa imaginea și textul pe o linie nouă */
    border: 1px solid #000000;
    border-radius: 26px;
    /* padding-bottom: 34px; */
    margin: 10px;
    margin-top: 35px;
}

.senfluaFormTipAsigLifeHover {

  font: 20px Alegreya;
  width: 110px;
  height: 104px;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* adăugat pentru a afișa imaginea și textul pe o linie nouă */
  border: 1px solid #000000;
  border-radius: 26px; 
  /* padding-bottom: 34px; */
  margin: 10px;

}

.TrimiteRaspunsul{
  margin-bottom: 10px;
}

.senfluaFormAltProdus{
  font: normal normal normal 20px/54px Alegreya;
  border: 1px solid #BDCCD5;
  padding: 0px 20px 0px 20px;
  border-radius: 91px;
  margin: 10px;
}

.senfluaFormAltProdus_active{
  font: normal normal normal 20px/54px Alegreya;
  border: 1px solid #000000;
  padding: 0px 20px 0px 20px;
  border-radius: 91px;
  margin: 10px;
}
.namesubsubTitle{
  font: normal normal normal 11px/30px Lato;
  color: #998E99;
}


.senfluaFormRedirectionare1{
  color: #000000;
  margin-top: 39px;
  border: 1px solid #D9D4D9;
    border-radius: 26px;
  text-decoration: underline;
    font: normal normal normal 20px/54px Alegreya;
  padding-left: 20px;
  padding-right: 20px;
}
.senfluaFormRedirectionare1:hover{
  color: #000000;
}

.senfluaFormRedirectionare2{
  font: normal normal normal 20px/54px Alegreya;
  border: 1px solid #D9D4D9;
    border-radius: 26px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 90px;
  margin-top: 25px;
}


