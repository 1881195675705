.FooterTravel{
  width: 100%;
  min-height: 30px;
  height: auto;
  padding-top: 65px;
  background: #EFECEF;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;

}