.FooterGrey{
  width: 100%;
  min-height: 128px !important;
  height: auto;
  background: #EBEBEB;
  letter-spacing: 0px;
  color: #000000;

}

.FooterGrey_center_left{
  padding-top: 32px;
  text-align: left;

}
.FooterGrey_center_left h3 {
  font: normal normal bold 13px/19px Alegreya !important;
  letter-spacing: 0.39px;
  color: #000000;
}

.FooterGrey_center_left p{
  font: normal normal normal 11px/19px Alegreya;
    letter-spacing: 0.33px;
    color: #000000;
}

.FooterGrey_center_right{
  padding-top: 32px;
  text-align: right;
}

.FooterGrey_center_right a{
  font: normal normal bold 13px/19px Alegreya;
  letter-spacing: 0.39px;
  color: #3D3D3D;
  padding-right: 15px;
}

@media  screen and (max-width: 1246px) {
  br {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .FooterGrey_center_left{

    text-align: center;
  }
  .FooterGrey_center_right{
    padding-top: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
}