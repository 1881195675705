.section_formRCA{
  width: 100%;
  min-height: 720px;
  height: auto;
  padding-top: 120px;
  
  background-color: #EEF5FC;
  text-align: center;
  
  margin: 0px;
  font: Alegreya;
  overflow: hidden;
  
}
.confirmare_rca_title{
  margin-top: 42px;
  text-align: center;
    font: normal normal bold 34px/44px Alegreya;
    letter-spacing: 0px;
    color: #000000;
}
.containerRCA__form{
 
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BDCCD5;
  border-radius: 91px;
  opacity: 1;
  min-height: 517px;
  
}

.sectionRCA__formConfirmare{
  margin-top: 50px !important;
  margin-bottom: 50px;
}

.sectionRCA__formConfirmare_subtitle{
  text-align: center;
    font: normal normal bold 20px/28px Alegreya;
    letter-spacing: 0px;
    color: #000000;
}

.sectionRCA__formConfirmare_text{
  text-align: center;
    font: normal normal normal 16px/24px Alegreya;
    letter-spacing: 0px;
    color: #000000;
}

.rca_btn_calatorie{
  
  width: 300px;
  height: auto;
  margin-bottom: 37px;
}

.rca_btn_calatorie:hover {
  cursor: pointer;
}

.check_btn{
  margin-left: 5px;
}

.sectionRCA__right{
  text-align: center;
}

.conRca_sticky {
  position: fixed;
  top: 25%;
  right: 5%;

  /* top: 250px; */

}
.conRca_sticky:hover {
  cursor: pointer;
}

.sectionRCA__Loading {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  font: normal normal bold 34px/44px Alegreya;
}