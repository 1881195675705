.container2{
    font-weight: 800;
    line-height: 55px;
    height: 800px;
    

}

.accordion__faq-heading{
  /*display: flex;*/
  /* justify-content: space-between; */
  
  align-items: center;
  text-align: center;
}
 
  .accordion-simple > .active {
    display: block;
  }
  
  .accordion__faq .inactive{
    display: none;
  }
  
  .accordion__faq > div{
    /*background-color: var(--white);
    margin-bottom: 20px;
    padding: 0px 15px;
    border-radius: 5px;
    cursor: pointer;*/

    text-align: center;
    font: normal normal normal 14px/21px Alegreya;
    letter-spacing: 0px;
    color: #000000;
    cursor: pointer;
    border-bottom: 1px solid #66B6FF;
    padding: 10px;
   
  }
  

  .accordion__faq-heading .active{
    color: var(--text-primary);
  }

  .icons{
    padding-right: 10px;
    display: inline;
    margin-top: -10px;
  }
  
  .accordion__faq-heading h3{
    display: inline;
    font: normal normal bold 16px/22px Alegreya;
    letter-spacing: 0px;
    color: #000000;
  }

  .accordion__faq-heading p{
    padding-top: 30;
  }
  
