.sectionCondtii {
    width: 100%;
    min-height: 316px;
    height: auto;
    background-color: #FBFBFB;

    font: Alegreya;
    overflow: hidden;




}

.sectionCondtiiText{
    text-align: left;
        font: normal normal normal 17px/17px Alegreya;
        color: #000000;
        margin-top: 30px;
}
