.hero_section {
  width: 100%;
  min-height: 750px;
  height: auto;
  background-color: #66B6FF;
  text-align: center;

  margin: 0px;
  padding-top: 200px;
  font: Alegreya;
  overflow: hidden;

  display: table;
}
 

.bt_resize{
  width: 202px;
  height: auto;
}

.textHero {

  text-align: left;

}

.title1 {
  font: normal normal bold 26px/31px Alegreya;
  margin-left: 10px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.title2 {
  margin-top: -25px;
  font: normal normal bold 56px/66px Alegreya;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.image-Travelbtn_img img {
  margin-right: 25px;
  margin-bottom: 35px;
}

.subtitleHero {
  font: normal normal bold 18px/24px Alegreya;
  margin-left: 10px;
  letter-spacing: 0px;
  color: #000000;
  width: 70%
}

.subtitleHeroTBI{
  font: normal normal 16px Alegreya;
  margin-top: -35px;
    margin-left: 10px;
    letter-spacing: 0px;
    color: #000000;
    width: 70%

}

.rocket {
  padding-top: 80px;
  margin-left: -60px;

}

.hello {
  margin-top: -190px;
  margin-left: -260px;
}

@media screen and (max-width: 767px) {

  .title1 {
    font: normal normal bold 21px Alegreya;
  }

  .title2 {
    margin-top: -20px;
    font: normal normal bold 44px Alegreya;
  }

  .subtitleHero {
    width: 100%;
    font: normal normal bold 14px Alegreya;
  }

  .hero_section {
    padding-top: 100px !important;
  }


  .hero_photo {
    padding-top: 10px;
  }

  .textHero {
    padding-top: 70px;
    text-align: left;
    margin-bottom: 10px;
  }



  .textHero {
    padding-top: 50px;
  }







}

@media screen and (max-width: 1000px) {


  .hero_photo {
    padding-top: -100px;

  }

  .textHero {

    text-align: left;
    margin-bottom: -30px;
  }

  .helloHome{
    margin-top: 50px;
    margin-bottom: 20px;
    width: 350px;
      height: auto;
  }



}

@media screen and (max-width: 360px) {

    .helloHome {
      margin-top: 50px;
      margin-bottom: 20px;
      width: 250px;
      height: auto;
    }
}