.section2{
  width: 100%;
  min-height: 370px;
  height: auto;
  background-color: #FBFBFB;
  padding-top: 134px;
  font: Alegreya;
  overflow: hidden;
  text-align: center;
  margin: 0px;
  display: table;

}


.section2__content{
  font: normal normal bold 48px/58px Alegreya;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-align: left;
  
}
.gift{
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {

  .section2__content{
    font: normal normal bold 39px Alegreya;
  }
  .gift{
    max-width: 60%;
    }

}

@media screen and (max-width: 360px) {
  .gift{
  max-width: 40%;
  margin-bottom: 30px;
  }
}


