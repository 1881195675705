.section_formTravel{
  width: 100%;
  min-height: 720px;
  height: auto;
  padding-top: 120px;
  
  background-color: #EFECEF;
  text-align: center;
  
  margin: 0px;
  font: Alegreya;
  overflow: hidden;
  
}
.containerTravel__lp{
 
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #EFECEF;
  border-radius: 91px;
  opacity: 1;
  min-height: 517px;
  
}

.sectionTravel__left{
text-align: left;
}

.sectionTravel__left p{
  
  letter-spacing: 0px;
  margin-left: 58px;
  text-align: left;
}
.sectionTravel__left_title1{
  font: normal normal bold 48px/49px Alegreya;
  margin-top: 52px;
  color: #000000;
}

.sectionTravel__left_title2 {
  font: normal normal bold 48px/49px Alegreya;
  margin-top: -20px;
  color: #FBBCFB;
}

.sectionTravel__left_text1{
  margin-top: 47px;
    text-align: left;
    font: normal normal bold 18px/24px Alegreya;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.sectionTravel__left_text2{
  font: normal normal bold 18px/24px Alegreya;
  text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.sectionTravel__right_title1{
  font: normal normal bold 36px/57px Amatic SC;
  text-align: right;
  margin-right: 71px;
  margin-top: 57px;
  color: #FBBCFB;
}

.sectionTravel__lp img {
  float: center;

}

.rca_right1,
.rca_right2,
.rca_right3{
  margin-bottom: 100px;
}

.Travel_btn {
  float: center !important;
}


.sectionTravel__left_title3{
  font: normal normal bold 48px/49px Alegreya;
  margin-top: -20px;
    color: #000000;
}

.sectionTravel__left_text1 {
  margin-top: 71px;
  text-align: left;
  font: normal normal bold 16px/22px Alegreya;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.sectionTravel__left_text2 {
  font: normal normal bold 16px/22px Alegreya;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.sectionTravel__left_text4{
    margin-top: 84px;
    font: normal normal bold 18px/24px Alegreya;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.slick-slide img {
  display: inline !important;
  float: center !important;
}
 
.carousel-indicators .active {
  background-color: #D9D4D9 !important;
}

.carousel-control-next{

  display: none !important;
}

.carousel-control-prev{
  display: none !important;
}

.slick-dots {
  position: absolute;
  bottom: 60px;
  list-style: none;  
  /* text-align: center; */
  text-align: right;
  right: 22%;
  width: 100%;
}

.slick-dots li {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}

.slick-dots li button {

  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #EFECEF !important;
  background-color: #FFFFFF !important;
  border: none;
  padding: 0;
  text-indent: -9999px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.5s ease;
}

.slick-dots li.slick-active button {
  opacity: 1;
  background: #EFECEF 0% 0% no-repeat padding-box !important;
  border: 1px solid #EFECEF !important;


}
.Travel_right1,
.Travel_right2,
.Travel_right3{
  margin-bottom: 100px;
}

.Travel_btn {
  margin-top: 20px;
  margin-left: 60px;
  width: 243px;
  height: auto;
}

.Travel_btn:hover {
  cursor: pointer;
}


.section_formTravel .slick-dots button {
  width: 18px !important;
  height: 18px !important;
  background: #BDCCD5 0% 0% no-repeat padding-box !important;
  border: 1px solid #BDCCD5 !important;
  opacity: 1;
  border-radius: 10px;
}


.section_formTravel .slick-dots {
  position: absolute;
  bottom: 60px;
  list-style: none;

  /* text-align: center; */
  text-align: right;
  right: 22%;
  width: 100%;
}

.section_formTravel .slick-dots li {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}

.section_formTravel .slick-dots li button {

  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #EFECEF !important;
  background-color: #FFFFFF !important;
  border: none;
  padding: 0;
  text-indent: -9999px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.5s ease;
}

.section_formTravel .slick-dots li.slick-active button {
  opacity: 1;
  background: #EFECEF 0% 0% no-repeat padding-box !important;
  border: 1px solid #EFECEF !important;


}


@media screen and (max-width: 1200px) {

  .Travel_right {
    margin-bottom: 30px;
  }

    .section_formTravel .slick-dots {
      bottom: 80px;
    }


}

@media screen and (max-width: 500px) {

  .Travel_right1{
    width: 300px;
    height: auto;
  }

  .Travel_right2 {
    width: 300px;
    height: auto;
  }

  .Travel_right3 {
    width: 200px;
    height: auto;
  }

  .containerTravel__lp {
    min-height: 980px;

  }

  .sectionTravel__left p {

    margin-left: 12px;

  }


}