.language_img{
  width: 25px;
  height: auto;
}
.button_asigurare{
  background: #FFD506;
  text-align: center;
  font: normal normal bold 16px/22px Alegreya;
  letter-spacing: 0px;
  color: #000000;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 37px;
  padding-right: 37px;
  border-radius: 40px;
  border-color: #FFD506;
}

.image-with-hover-cursor {
  cursor: default;
}

.image-with-hover-cursor:hover {
  cursor: pointer;
}

.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid black;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 9999;
  text-align: center;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  z-index: 9998;
}

.popup-logo {
  margin-top: 20px;
  margin-bottom: 10px;
}

.popup-text a {
  font: normal normal bold 16px/26px Alegreya;
  color: #000000;
}

.popup-text a:hover {
  font: normal normal bold 16px/26px Alegreya;
  color: #000000;
}

.popup-text {
  font: normal normal normal 16px/26px Alegreya;
  color: #000000;
}

.popup-button {
  background-color: #FFD506;
  width: 103px;
  height: 31px;
  border: 1px solid #FFD506;
  border-radius: 10px;
}

.button-text {
  position: absolute;
  top: 30%;
  /* Ajustează vertical pozitionarea textului */
  left: 50%;
  /* Ajustează orizontal pozitionarea textului */
  transform: translate(-60%, -50%);
  /* Centrează textul */
  color: black;
  font: normal normal bold 16px/22px Alegreya;
  text-align: center;

  /* Adaugă stiluri pentru text pe un singur rând */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 99;
}

.image-button {
  position: relative;
  display: inline-block;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
